import React from 'react';

import tourOiro1 from './../../assets/tourCaleOiro/grafema_tour-guide-cale-do-oiro_01.jpg'
import tourOiro2 from './../../assets/tourCaleOiro/grafema_tour-guide-cale-do-oiro_02.jpg'
import tourOiro3 from './../../assets/tourCaleOiro/grafema_tour-guide-cale-do-oiro_03.jpg'
import tourOiro4 from './../../assets/tourCaleOiro/grafema_tour-guide-cale-do-oiro_04.jpg'
import tourOiro5 from './../../assets/tourCaleOiro/grafema_tour-guide-cale-do-oiro_05.jpg'
import tourOiro6 from './../../assets/tourCaleOiro/grafema_tour-guide-cale-do-oiro_06.jpg'
import tourOiro7 from './../../assets/tourCaleOiro/grafema_tour-guide-cale-do-oiro_07.jpg'


import {SingleImagePanel, ThreeImagePanel, ProjectTextPanel} from './../componentUtils/projectImgPanel.js'
import * as Utils from './../../utils/utils.js';

class TourOiroProj extends React.Component {

    render() {
    
        return (
            <div>
                <SingleImagePanel imgSource={tourOiro1}/>
                <ThreeImagePanel 
                    imgSourceMiddle={tourOiro2}
                    imgSourceRight={tourOiro3}/>
                 <SingleImagePanel imgSource={tourOiro4}/>
                 <SingleImagePanel imgSource={tourOiro5}/>
                 <SingleImagePanel imgSource={tourOiro6}/>
                 <SingleImagePanel imgSource={tourOiro7}
                    additionalStyle="lastPanelMarginBottom"/>
                <ProjectTextPanel
                    projectTitle="titleCale"
                    projectText="bodyCale"
                    projectItems="pageItemsCale"
                    projectFotoText="none"
                    navLeftLabel="titlePCI"
                    navRightLabel="titleCampanhaUA"
                    navigateTo={this.props.navigateTo}
                    leftNavUrl={Utils.grafemaUrls.pciProject}
                    rightNavUrl={Utils.grafemaUrls.campanhaUAProject}
                />
            </div>
        );
    }
}

export default TourOiroProj;