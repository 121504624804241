import React from 'react';

import './../style/desktopPPolicy.css';
import './../style/mobilePPolicy.css';

import * as Utils from './../utils/utils.js'
import { FormattedMessage } from "react-intl";

class PPolicyPage extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
    
        return (
            <div className="bannerMainContainer">
                <div className="bannerLeftSection">
                    <div>
                        <FormattedMessage id="pPolicy"/>
                    </div>
                </div>
                <div className="bannerRightContainer">
                    <div className="bannerRightContainerText">
                        <FormattedMessage id="pPolicyText1"/>
                    </div>
                    <div className="bannerRightContainerText">
                        <FormattedMessage id="pPolicyText2"/>
                    </div>
                    <div className="bannerRightContainerText">
                        <FormattedMessage id="pPolicyText3"/>
                    </div>
                    <div className="bannerRightContainerText">
                        <FormattedMessage id="pPolicyText4"/>
                    </div>
                    <div className="bannerRightContainerText">
                        <FormattedMessage id="pPolicyText5"/>
                    </div>
                    <div className="bannerRightContainerText">
                        <FormattedMessage id="pPolicyText6"/>
                    </div>
                </div>
            </div>
        );
    }
}

export default PPolicyPage;