import React from 'react';

import essence1 from './../../assets/the-essence-collective/grafema_the-essence-collective_01.jpg'
import essence2 from './../../assets/the-essence-collective/grafema_the-essence-collective_02.jpg'
import essence3 from './../../assets/the-essence-collective/grafema_the-essence-collective_03.jpg'
import essence4 from './../../assets/the-essence-collective/grafema_the-essence-collective_04.jpg'
import essence5 from './../../assets/the-essence-collective/grafema_the-essence-collective_05.jpg'
import essence6 from './../../assets/the-essence-collective/grafema_the-essence-collective_06.jpg'
import essence7 from './../../assets/the-essence-collective/grafema_the-essence-collective_07.mp4'
import essence8 from './../../assets/the-essence-collective/grafema_the-essence-collective_08.jpg'
import essence9 from './../../assets/the-essence-collective/grafema_the-essence-collective_09.jpg'
import essence10 from './../../assets/the-essence-collective/grafema_the-essence-collective_10.mp4'
import essence11 from './../../assets/the-essence-collective/grafema_the-essence-collective_11.jpg'
import essence12 from './../../assets/the-essence-collective/grafema_the-essence-collective_12.jpg'
import essence13 from './../../assets/the-essence-collective/grafema_the-essence-collective_13.jpg'
import essence14 from './../../assets/the-essence-collective/grafema_the-essence-collective_14.jpg'
import essence15 from './../../assets/the-essence-collective/grafema_the-essence-collective_15.jpg'
import essence16 from './../../assets/the-essence-collective/grafema_the-essence-collective_16.jpg'
import essence17 from './../../assets/the-essence-collective/grafema_the-essence-collective_17.jpg'
import essence18 from './../../assets/the-essence-collective/grafema_the-essence-collective_18.jpg'
import essence19 from './../../assets/the-essence-collective/grafema_the-essence-collective_19.jpg'
import essence20 from './../../assets/the-essence-collective/grafema_the-essence-collective_20.jpg'
import essence21 from './../../assets/the-essence-collective/grafema_the-essence-collective_21.jpg'

import {SingleImagePanel, ThreeImagePanel, ProjectTextPanel} from './../componentUtils/projectImgPanel.js'
import * as Utils from './../../utils/utils.js';

class EssenceProj extends React.Component {

    render() {
    
        return (
            <div>
                <SingleImagePanel imgSource={essence1}/>
                <ThreeImagePanel 
                    imgSourceLeft={essence2}
                    imgSourceRight={essence3}/>
                <SingleImagePanel imgSource={essence4}/>
                <SingleImagePanel imgSource={essence5}/>
                <ThreeImagePanel 
                    imgSourceLeft={essence6}
                    imgSourceMiddle={essence7}/>
                <SingleImagePanel imgSource={essence8}/>
                <SingleImagePanel imgSource={essence9}/>
                <ThreeImagePanel 
                    imgSourceMiddle={essence10}
                    imgSourceRight={essence11}/>
                <SingleImagePanel imgSource={essence12}/>
                <ThreeImagePanel 
                    imgSourceLeft={essence13}
                    imgSourceRight={essence14}/>
                <SingleImagePanel imgSource={essence15}/>
                <SingleImagePanel imgSource={essence16}/>
                <ThreeImagePanel 
                    imgSourceMiddle={essence17}
                    imgSourceRight={essence18}/>
                <SingleImagePanel imgSource={essence19}/>
                <ThreeImagePanel 
                    imgSourceLeft={essence20}
                    imgSourceMiddle={essence21}
                    additionalStyle="lastPanelMarginBottom"/>

                <ProjectTextPanel
                    projectTitle="titleEssenceCol"
                    projectText="bodyEssenceColTitle"
                    projectItems="pageItemsEssenseCol"
                    projectFotoText="none"
                    navLeftLabel="titleUmmi"
                    navRightLabel="titleCiceo"
                    navigateTo={this.props.navigateTo}
                    leftNavUrl={Utils.grafemaUrls.ummitProject}
                    rightNavUrl={Utils.grafemaUrls.cicecoProject}
                />
            </div>
        );
    }
}

export default EssenceProj;