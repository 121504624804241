import React from 'react';

import { FormattedMessage } from "react-intl";
import * as Utils from './../../utils/utils.js';

class GdprBanner extends React.Component {
    
    constructor(props) {
      super(props);
          
      this.pPolicyBanner = React.createRef()
    }
    
    componentDidMount() {
    
      var myStorage = window.localStorage;
      if(!myStorage.getItem('acceptedPpolicy')) {

          //console.log("No banner");
          this.pPolicyBanner.current.style.visibility = 'visible';
      }
      else
          this.pPolicyBanner.current.style.visibility = 'hidden';
      //console.log("Corru");
    }

    hidePPilocyBanner() {

      var myStorage = window.localStorage;
      myStorage.setItem('acceptedPpolicy', 'accepted');
      this.pPolicyBanner.current.style.visibility = 'hidden';
    }
  
    render() {
  
      return(
        <div className="gDPRContainerWrapper" ref={this.pPolicyBanner}>
            <div className="gDPRTextContainer">
                <span className="gDPRText" >
                    <FormattedMessage id="gDPRBannerPart1"/>
                    <span style={{textDecoration: "underline", cursor: "pointer"}} onClick={() => this.props.navigateTo(Utils.grafemaUrls.pPolicyPage)}>cookies</span>
                    <FormattedMessage id="gDPRBannerPart2"/>
                    <span className="gDPRutton" style={{cursor: "pointer"}} onClick={() => this.hidePPilocyBanner()}>OK</span>
                </span>
            </div>
        </div>
      );
    }
  }

  export default GdprBanner;