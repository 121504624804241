import React from 'react';

import designFac1 from './../../assets/designFactory/grafema_design-factory-kitchen-pictograms_01.jpg'
import designFac2 from './../../assets/designFactory/grafema_design-factory-kitchen-pictograms_02.jpg'
import designFac3 from './../../assets/designFactory/grafema_design-factory-kitchen-pictograms_03.jpg'
import designFac4 from './../../assets/designFactory/grafema_design-factory-kitchen-pictograms_04.gif'
import designFac5 from './../../assets/designFactory/grafema_design-factory-kitchen-pictograms_05.jpg'
import designFac6 from './../../assets/designFactory/grafema_design-factory-kitchen-pictograms_06.jpg'
import designFac7 from './../../assets/designFactory/grafema_design-factory-kitchen-pictograms_07.jpg'
import designFac8 from './../../assets/designFactory/grafema_design-factory-kitchen-pictograms_08.gif'
import designFac9 from './../../assets/designFactory/grafema_design-factory-kitchen-pictograms_09.jpg'


import {SingleImagePanel, ThreeImagePanel, ProjectTextPanel} from './../componentUtils/projectImgPanel.js'
import * as Utils from './../../utils/utils.js';

class DesignFactoryProj extends React.Component {

    render() {
    
        return (
            <div>
                <SingleImagePanel imgSource={designFac1}/>
                <ThreeImagePanel 
                    imgSourceLeft={designFac2}
                    imgSourceRight={designFac3}/>
                <SingleImagePanel imgSource={designFac4}/>
                <SingleImagePanel imgSource={designFac5}/>
                <ThreeImagePanel 
                    imgSourceLeft={designFac6}
                    imgSourceMiddle={designFac7}/>
                <SingleImagePanel imgSource={designFac8}/>
                <SingleImagePanel imgSource={designFac9}
                    additionalStyle="lastPanelMarginBottom"/>
                <ProjectTextPanel
                    projectTitle="titleDesignFactory"
                    projectText="bodyDesignFactory"
                    projectItems="pageItemsDesignFactory"
                    projectFotoText="none"
                    navLeftLabel="titleMartini"
                    navRightLabel="titleNutrana"
                    navigateTo={this.props.navigateTo}
                    leftNavUrl={Utils.grafemaUrls.martiniProject}
                    rightNavUrl={Utils.grafemaUrls.nutranaProject}
                />
            </div>
        );
    }
}

export default DesignFactoryProj;