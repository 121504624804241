import React from 'react';

import martini1 from './../../assets/martini/grafema_martini-contest_01.jpg'
import martini2 from './../../assets/martini/grafema_martini-contest_02.mp4'
import martini3 from './../../assets/martini/grafema_martini-contest_03.jpg'
import martini4 from './../../assets/martini/grafema_martini-contest_04.jpg'
import martini5 from './../../assets/martini/grafema_martini-contest_05.jpg'
import martini6 from './../../assets/martini/grafema_martini-contest_06.jpg'
import martini7 from './../../assets/martini/grafema_martini-contest_07.jpg'
import martini8 from './../../assets/martini/grafema_martini-contest_08.jpg'
import martini9 from './../../assets/martini/grafema_martini-contest_09.jpg'
import martini10 from './../../assets/martini/grafema_martini-contest_10.mp4'
import martini11 from './../../assets/martini/grafema_martini-contest_11.jpg'
import martini12 from './../../assets/martini/grafema_martini-contest_12.jpg'
import martini13 from './../../assets/martini/grafema_martini-contest_13.mp4'


import {SingleImagePanel, ThreeImagePanel, ProjectTextPanel} from './../componentUtils/projectImgPanel.js'
import * as Utils from './../../utils/utils.js';

class MartiniProj extends React.Component {

    render() {
    
        return (
            <div>
                <SingleImagePanel imgSource={martini1}/>
                <SingleImagePanel imgSource={martini2}/>
                <ThreeImagePanel 
                    imgSourceLeft={martini3}
                    imgSourceMiddle={martini4}/>
                <SingleImagePanel imgSource={martini5}/>
                <SingleImagePanel imgSource={martini6}/>
                <SingleImagePanel imgSource={martini7}/>
                <ThreeImagePanel 
                    imgSourceLeft={martini8}
                    imgSourceMiddle={martini9}/>
                <SingleImagePanel imgSource={martini10}/>
                <ThreeImagePanel 
                    imgSourceMiddle={martini11}
                    imgSourceRight={martini12}/>
                <SingleImagePanel imgSource={martini13}
                    additionalStyle="lastPanelMarginBottom"/>
                <ProjectTextPanel
                    projectTitle="titleMartini"
                    projectText="bodyMartini"
                    projectItems="pageItemsMartini"
                    projectFotoText="none"
                    navLeftLabel="titlePilates"
                    navRightLabel="titleDesignFactory"
                    navigateTo={this.props.navigateTo}
                    leftNavUrl={Utils.grafemaUrls.pilatesProject}
                    rightNavUrl={Utils.grafemaUrls.designFactoryProject}
                />
            </div>
        );
    }
}

export default MartiniProj;