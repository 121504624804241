import React from 'react';

import './../style/desktopStudio.css';
import './../style/mobileStudio.css';

import studioImage from './../assets/grafema-studio.mp4'
import { FormattedMessage, FormattedHTMLMessage} from "react-intl";

class StudioPage extends React.Component {

    constructor(props) {
      super(props);
      this.handleProjectClick = this.handleProjectClick.bind(this);
      this.myTitleRefMirror = React.createRef();
      this.myTitleRef = React.createRef();
      this.state = {
          wordIndex: 0,
          wordChar: 1,
          wordCurDirectionForward: true,
      }
    }

    componentDidMount() {
        
        this.state.wordIndex = 0;
        this.state.wordChar = 1;
        this.state.wordCurDirectionForward = true;
        this.rewriteTitleFunction();

    }

    handleProjectClick(path) {

        this.props.props.history.push(path);
    }

    rewriteTitleFunction() {

        if(this.myTitleRefMirror.current) {

            var titlesString = this.myTitleRefMirror.current.innerText;
            // console.log(titlesString);

            var titlesArray = titlesString.split(" ");
            // console.log("Array: " + titlesArray);
            // console.log("Array length: " + titlesArray.length);
            // console.log("WordIndex: " + this.state.wordIndex);
            // console.log("WordChar: " + this.state.wordChar);
            // console.log("Word: " + titlesArray[this.state.wordIndex].slice(0, this.state.wordChar));
            this.myTitleRef.current.innerText = titlesArray[this.state.wordIndex].slice(0, this.state.wordChar);
            if(this.state.wordCurDirectionForward) {
                
                if(this.state.wordChar >= titlesArray[this.state.wordIndex].length) {

                    this.state.wordChar = titlesArray[this.state.wordIndex].length - 1;
                    this.state.wordCurDirectionForward = false;
                }
                else {
                    this.state.wordChar++;
                }
            }
            else {

                if(this.state.wordChar > 0) {
                    this.state.wordChar--;
                }
                else {

                    if(this.state.wordIndex < titlesArray.length - 1)
                        this.state.wordIndex++;
                    else
                        this.state.wordIndex = 0;

                    this.state.wordChar++;
                    this.state.wordCurDirectionForward = true;
                }
            }
            setTimeout(()=> this.rewriteTitleFunction(), 150);
        }
    }

    render() {
    
        return (
            <div className="studioMainContainer">
                <div className="studioWelcomeContainer">
                    <div ref={this.myTitleRefMirror} className="studioTitleMirror"><FormattedMessage id="studioTitle1"/></div>
                    <div className="studioWelcomeTitle">
                        <span ref={this.myTitleRef}>|</span>
                        <span className="studioCursor">|</span>
                    </div>
                    <div className="studioWelcomeText">
                        <FormattedHTMLMessage id="studioSentence1"/>
                    </div>
                </div>
                <div className="studioImgContainer">
                    <div className="studioImgPanel">
                        <video src={studioImage} className="studioImg" playsInline autoPlay muted loop/>
                        <div className="studioImgLeftOver"></div>
                        <div className="studioImgRightOver"></div>
                    </div>
                </div>
                <div className="studioDescContainer">
                    <div className="studioContactSection">
                        <div>
                            <FormattedMessage id="studioGetInContact1"/>
                        </div>
                        <div>
                            <FormattedMessage id="studioGetInContact2"/>
                        </div>
                    </div>
                    <div className="studioDescRightInnerContainer">
                        <div className="studioDescTextSection">
                            <FormattedMessage id="studioGrafemaText"/>
                        </div>
                        <div className="studioDescTextSection studioDescTextSectionMargin">
                            <FormattedMessage id="studioFoundersText"/>
                        </div>
                    </div>
                </div>
                <div className="whatWeDoContainer">
                    <div className="whatWeDo">
                        <FormattedHTMLMessage id="studioWhatwedo"/>
                    </div>
                    <div className="whatWeDoRightContainer">
                        <div className="whatWeDoRightContainerLeftSection">
                            <FormattedHTMLMessage id="studioItemColumn1"/>
                        </div>
                        <div className="whatWeDoRightContainerRightSection">
                            <FormattedHTMLMessage id="studioItemColumn2"/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default StudioPage;
