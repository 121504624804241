import React from 'react';

import './../../style/overlay.css';

import grafema from './../../assets/grafema.png'
import xAsset from './../../assets/x.svg'

class Overlay extends React.Component {
  
    constructor(props) {
      super(props);
      this.myRef = React.createRef();
      this.myCanvasRef = React.createRef();
      
      this.state = {
          animTime: 0,
          isAnimating: false,
          isOn: false,
          circleSize: 0,
          rubberSize: 0,
          maxCircleSize: 0,
          circlePosX: 0,
          circlePosY: 0,
          timerId: 0
      }

      this.initMoveHandler = this.initMoveHandler.bind(this);
      this.windowResizeEvent = this.windowResizeEvent.bind(this);
      this.hideOverlayEvent = this.hideOverlay.bind(this);
      this.touchUpHandler = this.touchUpHandler.bind(this);
    }
  
    componentDidMount() {
      
        this.myCanvasRef.current.addEventListener('mousemove', this.initMoveHandler);
        this.myCanvasRef.current.addEventListener('touchmove', this.initMoveHandler);
        this.myCanvasRef.current.addEventListener('touchend', this.touchUpHandler);
        this.myCanvasRef.current.addEventListener('mousedown', this.hideOverlayEvent)
        
        window.addEventListener('resize', this.windowResizeEvent);
        
    }

    componentWillUnmount() {
        
        this.myCanvasRef.current.removeEventListener('mousemove', this.initMoveHandler);
        this.myCanvasRef.current.removeEventListener('touchmove', this.initMoveHandler);
        this.myCanvasRef.current.removeEventListener('mousedown', this.hideOverlayEvent);
        this.myCanvasRef.current.removeEventListener('touchend', this.touchUpHandler);

        window.removeEventListener('resize', this.windowResizeEvent);
    }

    windowResizeEvent() {

        if(this.state.isOn) {
            this.hideOverlay();
        }
    }

    handleXClick(e) {
        this.hideOverlay();
        e.preventDefault();
    }
    
    touchUpHandler(e) {
        // console.log("touch up");
        this.state.curCirclePosArray = "";
    }


    initMoveHandler(e) {
        
        if(this.state.isOn && !this.state.isAnimating) {
        
            if(e.touches) {
                
                // console.log("Detected touches: " + e.touches.length);
                if(!this.state.curCirclePosArray) {
                    this.state.curCirclePosArray = []
                    for(var i = 0; i < e.touches.length; i++) {
                        
                        let mx = e.touches[i].clientX;
                        let my = e.touches[i].clientY;

                        this.clearCircle(mx, my, this.state.rubberSize);
                        this.state.curCirclePosArray.push({
                            mx: mx,
                            my: my
                        });
                    }
                }
                else {

                    for(var i = 0; i < e.touches.length; i++) {
                        
                        let mx = e.touches[i].clientX;
                        let my = e.touches[i].clientY;

                        if(this.state.curCirclePosArray[i]) {

                            var xDer = (mx > this.state.curCirclePosArray[i].mx)? 1: -1;
                            var yDer = (my > this.state.curCirclePosArray[i].my)? 1: -1;;
                            var curX = this.state.curCirclePosArray[i].mx;
                            var curY = this.state.curCirclePosArray[i].my;
                            // console.log("Initial X " + curX + " Target X: " + mx + " x der: " + xDer);
                            // console.log("Initial Y " + curY + " Target Y: " + my + " x der: " + yDer);

                            var inWhile = true;
                            while(inWhile) {

                                this.clearCircle(curX, curY, this.state.rubberSize);
                                inWhile = false;
                                if( (xDer>0 && curX < mx) || (xDer < 0 && curX > mx) ) {
                                    curX += xDer;
                                    inWhile = true;
                                }
                                if( (yDer>0 && curY < my) || (yDer < 0 && curY > my) ) {
                                    curY += yDer
                                    inWhile = true;
                                }
                                
                                // console.log("Cur X: " + curX)
                                // console.log("Cur Y: " + curY)
                            }
                            this.state.curCirclePosArray[i] = {
                                mx: mx,
                                my: my
                            };
                        }
                        else {

                            this.clearCircle(mx, my, this.state.rubberSize);
                        }
                    }
                } 
                e.preventDefault();
                e.stopImmediatePropagation();
            }
            else {

                let mx = e.clientX;
                let my = e.clientY;

                if(!this.state.curCirclePos) {
                    
                    this.clearCircle(mx, my, this.state.rubberSize);
                    this.state.curCirclePos = {
                        mx: mx,
                        my: my
                    };
                }
                else {

                    var xDer = (mx > this.state.curCirclePos.mx)? 1: -1;
                    var yDer = (my > this.state.curCirclePos.my)? 1: -1;;
                    var curX = this.state.curCirclePos.mx;
                    var curY = this.state.curCirclePos.my;
                    // console.log("Initial X " + curX + " Target X: " + mx + " x der: " + xDer);
                    // console.log("Initial Y " + curY + " Target Y: " + my + " x der: " + yDer);
                    var inWhile = true;
                    while(inWhile) {
                        
                        this.clearCircle(curX, curY, this.state.rubberSize);
                        inWhile = false;
                        if( (xDer>0 && curX < mx) || (xDer < 0 && curX > mx) ) {
                            curX += xDer;
                            inWhile = true;
                        }
                        if( (yDer>0 && curY < my) || (yDer < 0 && curY > my) ) {
                            curY += yDer
                            inWhile = true
                        }
                        
                        // console.log("Cur X: " + curX)
                        // console.log("Cur Y: " + curY)

                    }
                    this.state.curCirclePos = {
                        mx: mx,
                        my: my
                    };
                }

                e.preventDefault();
                e.stopImmediatePropagation();
            }
        }
    }


    triggerOverlay() {

        if(!this.state.isOn && !this.state.isAnimating) {
            
            this.state.isAnimating = true;
            this.state.isOn = true;
            this.myRef.current.style.display = "block";
            this.myRef.current.style.zIndex = 20;

            if (typeof window !== "undefined") {
                
                this.myCanvasRef.current.width = window.innerWidth;
                this.myCanvasRef.current.height = window.innerHeight;
            }
            this.state.circlePosX = window.innerWidth/2;
            this.state.circlePosY = window.innerHeight/2;
            this.state.circleSize = 0.05 * window.innerWidth;
            this.state.rubberSize = 0.05 * window.innerWidth;
            this.state.maxCircleSize = (window.innerHeight > window.innerWidth)? window.innerHeight : window.innerWidth;
            this.renderCircle(this.state.circlePosX, this.state.circlePosY, this.state.circleSize);
            // this.clearCircle(window.innerWidth/2, window.innerHeight/2, 50);
            this.runInitialLoop();
        }
    }

    hideOverlay() {

        if(this.state.isOn) {
            
            const context = this.myCanvasRef.current.getContext('2d');
            context.clearRect(0, 0, window.innerWidth, window.innerHeight);

            this.myRef.current.style.display = "none";
            this.myRef.current.style.zIndex = -10;
            this.state.isOn = false;
            this.state.isAnimating = false;
        }
    }
    
    runInitialLoop() {

        //console.log("rol " + this.state.circleSize);
        this.state.circleSize += 10;
        if(this.state.circleSize <= this.state.maxCircleSize && this.state.isAnimating) {
            this.renderCircle(this.state.circlePosX, this.state.circlePosY, this.state.circleSize);
            setTimeout(() => {
                this.runInitialLoop() 
            }, 25);
        }
        else {
            // Animation is cleared so we can officially say we are on
            this.state.isAnimating = false;
        }
    }

    renderCircle(posX, posY, radius, color) {

        var context = this.myCanvasRef.current.getContext('2d');
        context.beginPath();
        context.arc(posX, posY, radius, 0, 2 * Math.PI);
        context.fillStyle = "rgba(232, 241, 219, 1)";
        context.fill();
        
    }

    clearCircle(posX, posY, radius)
    {
        //console.log("clearing: " + posX + "," + posY);
        var context = this.myCanvasRef.current.getContext('2d');
        context.beginPath();
        context.arc(posX, posY, radius, 0, 2 * Math.PI);
        context.save()
        context.clip();
        context.clearRect(posX - radius - 1, posY - radius - 1,
            radius * 2 + 2, radius * 2 + 2);
        context.restore()

    }

    render() {
      
        return(
          <div ref={this.myRef} className="overlay">
              <div className="topSectionContainer">
                <img src={grafema} className="overlayLogo"/>
                <img src={xAsset} className="xAsset" onClick={(event) => {this.handleXClick(event);}}/>
              </div>
              <canvas ref={this.myCanvasRef} className="canvasContainer">
              
              </canvas>
          </div>
        );
      }
  }

  export default Overlay;