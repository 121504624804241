import React from 'react';

import pci1 from './../../assets/pciAnniversary/grafema_f1rst_01.jpg'
import pci2 from './../../assets/pciAnniversary/grafema_f1rst_02.mp4'
import pci3 from './../../assets/pciAnniversary/grafema_f1rst_03.jpg'
import pci4 from './../../assets/pciAnniversary/grafema_f1rst_04.jpg'
import pci5 from './../../assets/pciAnniversary/grafema_f1rst_05.jpg'
import pci6 from './../../assets/pciAnniversary/grafema_f1rst_06.jpg'
import pci7 from './../../assets/pciAnniversary/grafema_f1rst_07.mp4'
import pci8 from './../../assets/pciAnniversary/grafema_f1rst_08.jpg'
import pci9 from './../../assets/pciAnniversary/grafema_f1rst_09.jpg'


import {SingleImagePanel, ThreeImagePanel, ProjectTextPanel} from './../componentUtils/projectImgPanel.js'
import * as Utils from './../../utils/utils.js';

class PciProj extends React.Component {

    render() {
    
        return (
            <div>
                <SingleImagePanel imgSource={pci1}/>
                <ThreeImagePanel 
                    imgSourceLeft={pci2}
                    imgSourceMiddle={pci3}/>
                 <SingleImagePanel imgSource={pci4}/>
                 <ThreeImagePanel 
                    imgSourceMiddle={pci5}
                    imgSourceRight={pci6}/>
                <SingleImagePanel imgSource={pci7}/>
                <ThreeImagePanel 
                    imgSourceLeft={pci8}
                    imgSourceRight={pci9}
                    additionalStyle="lastPanelMarginBottom"/>
                <ProjectTextPanel
                    projectTitle="titlePCI"
                    projectText="bodyPCI"
                    projectItems="pageItemsPCI"
                    projectFotoText="none"
                    navLeftLabel="titleMinhaCasa"
                    navRightLabel="titleCale"
                    navigateTo={this.props.navigateTo}
                    leftNavUrl={Utils.grafemaUrls.minhaCasaProject}
                    rightNavUrl={Utils.grafemaUrls.caleProject}
                />
            </div>
        );
    }
}

export default PciProj;