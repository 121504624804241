import React from 'react';

import ciceco1 from './../../assets/ciceco/grafema_ciceco_01.mp4'
import ciceco2 from './../../assets/ciceco/grafema_ciceco_02.jpg'
import ciceco3 from './../../assets/ciceco/grafema_ciceco_03.jpg'
import ciceco4 from './../../assets/ciceco/grafema_ciceco_04.jpg'
import ciceco5 from './../../assets/ciceco/grafema_ciceco_05.jpg'
import ciceco6 from './../../assets/ciceco/grafema_ciceco_06.jpg'
import ciceco7 from './../../assets/ciceco/grafema_ciceco_07.jpg'
import ciceco8 from './../../assets/ciceco/grafema_ciceco_08.jpg'
import ciceco9 from './../../assets/ciceco/grafema_ciceco_09.jpg'
import ciceco10 from './../../assets/ciceco/grafema_ciceco_10.jpg'
import ciceco11 from './../../assets/ciceco/grafema_ciceco_11.jpg'

import {SingleImagePanel, ThreeImagePanel, ProjectTextPanel} from './../componentUtils/projectImgPanel.js'
import * as Utils from './../../utils/utils.js';

class CicecoProj extends React.Component {

    render() {
    
        return (
            <div>
                <SingleImagePanel imgSource={ciceco1}/>
                <ThreeImagePanel 
                    imgSourceMiddle={ciceco2}
                    imgSourceRight={ciceco3}/>
                <SingleImagePanel imgSource={ciceco4}/>
                <SingleImagePanel imgSource={ciceco5}/>
                <ThreeImagePanel 
                    imgSourceLeft={ciceco6}
                    imgSourceRight={ciceco7}/>
                <SingleImagePanel imgSource={ciceco8}/>
                <ThreeImagePanel 
                    imgSourceLeft={ciceco9}
                    imgSourceMiddle={ciceco10}/>
                <SingleImagePanel imgSource={ciceco11}
                    additionalStyle="lastPanelMarginBottom"/>

                <ProjectTextPanel
                    projectTitle="titleCiceo"
                    projectText="bodyCiceco"
                    projectItems="pageItemsCiceco"
                    projectFotoText="none"
                    navLeftLabel="titleEssenceCol"
                    navRightLabel="titleAmcc"
                    navigateTo={this.props.navigateTo}
                    leftNavUrl={Utils.grafemaUrls.essenceColProject}
                    rightNavUrl={Utils.grafemaUrls.amccProject}
                />
            </div>
        );
    }
}

export default CicecoProj;