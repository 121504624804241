import React from 'react';

import amcc1 from './../../assets/amcc/grafema_amcc_01.mp4'
import amcc2 from './../../assets/amcc/grafema_amcc_02.jpg'
import amcc3 from './../../assets/amcc/grafema_amcc_03.jpg'
import amcc4 from './../../assets/amcc/grafema_amcc_04.jpg'
import amcc5 from './../../assets/amcc/grafema_amcc_05.mp4'
import amcc6 from './../../assets/amcc/grafema_amcc_06.jpg'
import amcc7 from './../../assets/amcc/grafema_amcc_07.jpg'
import amcc8 from './../../assets/amcc/grafema_amcc_08.jpg'
import amcc9 from './../../assets/amcc/grafema_amcc_09.jpg'
import amcc10 from './../../assets/amcc/grafema_amcc_10.jpg'
import amcc11 from './../../assets/amcc/grafema_amcc_11.jpg'
import amcc12 from './../../assets/amcc/grafema_amcc_12.jpg'
import amcc13 from './../../assets/amcc/grafema_amcc_13.jpg'

import {SingleImagePanel, ThreeImagePanel, ProjectTextPanel} from './../componentUtils/projectImgPanel.js'
import * as Utils from './../../utils/utils.js';

class AmccProj extends React.Component {

    render() {
    
        return (
            <div>
                <SingleImagePanel imgSource={amcc1}/>
                <ThreeImagePanel 
                    imgSourceMiddle={amcc2}
                    imgSourceRight={amcc3}/>
                <SingleImagePanel imgSource={amcc4}/>
                <SingleImagePanel imgSource={amcc5}/>
                <ThreeImagePanel 
                    imgSourceLeft={amcc6}
                    imgSourceRight={amcc7}/>
                <SingleImagePanel imgSource={amcc8}/>
                <SingleImagePanel imgSource={amcc9}/>
                <ThreeImagePanel 
                    imgSourceMiddle={amcc10}
                    imgSourceRight={amcc11}/>
                <SingleImagePanel imgSource={amcc12}/>
                <SingleImagePanel imgSource={amcc13}
                    additionalStyle="lastPanelMarginBottom"/>

                <ProjectTextPanel
                    projectTitle="titleAmcc"
                    projectText="bodyAmcc"
                    projectItems="pageItemsAmcc"
                    projectFotoText="none"
                    navLeftLabel="titleCiceo"
                    navRightLabel="titleclivitalbio"
                    navigateTo={this.props.navigateTo}
                    leftNavUrl={Utils.grafemaUrls.cicecoProject}
                    rightNavUrl={Utils.grafemaUrls.clivitalbioProject}
                />
            </div>
        );
    }
}

export default AmccProj;