import React from 'react';

import angura1 from './../../assets/angura/grafema_angura_01.mp4'
import angura2 from './../../assets/angura/grafema_angura_02.mp4'
import angura3 from './../../assets/angura/grafema_angura_03.mp4'
import angura4 from './../../assets/angura/grafema_angura_04.jpg'
import angura5 from './../../assets/angura/grafema_angura_05.jpg'
import angura6 from './../../assets/angura/grafema_angura_06.jpg'
import angura7 from './../../assets/angura/grafema_angura_07.jpg'
import angura8 from './../../assets/angura/grafema_angura_08.jpg'
import angura9 from './../../assets/angura/grafema_angura_09.jpg'
import angura10 from './../../assets/angura/grafema_angura_10.mp4'
import angura11 from './../../assets/angura/grafema_angura_11.jpg'
import angura12 from './../../assets/angura/grafema_angura_12.jpg'
import angura13 from './../../assets/angura/grafema_angura_13.jpg'
import angura14 from './../../assets/angura/grafema_angura_14.mp4'


import {SingleImagePanel, ThreeImagePanel, ProjectTextPanel} from './../componentUtils/projectImgPanel.js'
import * as Utils from './../../utils/utils.js';

class AnguraProj extends React.Component {

    render() {
    
        return (
            <div>
                <SingleImagePanel imgSource={angura1}/>
                <ThreeImagePanel 
                    imgSourceLeft={angura2}
                    imgSourceMiddle={angura3}/>
                <SingleImagePanel imgSource={angura4}/>
                <SingleImagePanel imgSource={angura5}/>
                <ThreeImagePanel 
                    imgSourceLeft={angura6}
                    imgSourceRight={angura7}/>
                <SingleImagePanel imgSource={angura8}/>
                <SingleImagePanel imgSource={angura9}/>
                <ThreeImagePanel 
                    imgSourceLeft={angura10}
                    imgSourceRight={angura11}/>
                <SingleImagePanel imgSource={angura12}/>
                <SingleImagePanel imgSource={angura13}/>
                <SingleImagePanel imgSource={angura14}
                    additionalStyle="lastPanelMarginBottom"/>
                    
                <ProjectTextPanel
                    projectTitle="titleAngura"
                    projectText="bodyAngura"
                    projectFotoText="fotoAngura"
                    fotoUrl="https://angurawear.com"
                    fotoGrafema={true}
                    projectItems="pageItemsAngura"
                    navLeftLabel="titleSecondLiferesin"
                    navRightLabel="titleCoimbra"
                    navigateTo={this.props.navigateTo}
                    leftNavUrl={Utils.grafemaUrls.secondliferesinProject}
                    rightNavUrl={Utils.grafemaUrls.coimbraProject}
                />
            </div>
        );
    }
}

export default AnguraProj;