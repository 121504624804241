import React from 'react';

import packagingOiro1 from './../../assets/packagingCaleOiro/grafema_packaging-cale-do-oiro_01.jpg'
import packagingOiro2 from './../../assets/packagingCaleOiro/grafema_packaging-cale-do-oiro_02.jpg'
import packagingOiro3 from './../../assets/packagingCaleOiro/grafema_packaging-cale-do-oiro_03.mp4'
import packagingOiro4 from './../../assets/packagingCaleOiro/grafema_packaging-cale-do-oiro_04.jpg'
import packagingOiro5 from './../../assets/packagingCaleOiro/grafema_packaging-cale-do-oiro_05.jpg'
import packagingOiro6 from './../../assets/packagingCaleOiro/grafema_packaging-cale-do-oiro_06.jpg'
import packagingOiro7 from './../../assets/packagingCaleOiro/grafema_packaging-cale-do-oiro_07.jpg'
import packagingOiro8 from './../../assets/packagingCaleOiro/grafema_packaging-cale-do-oiro_08.jpg'


import {SingleImagePanel, ThreeImagePanel, ProjectTextPanel} from './../componentUtils/projectImgPanel.js'
import * as Utils from './../../utils/utils.js';

class PackagingCaleOiroProj extends React.Component {

    render() {
    
        return (
            <div>
                <SingleImagePanel imgSource={packagingOiro1}/>
                <ThreeImagePanel 
                    imgSourceLeft={packagingOiro2}
                    imgSourceMiddle={packagingOiro3}/>
                <SingleImagePanel imgSource={packagingOiro4}/>
                <SingleImagePanel imgSource={packagingOiro5}/>
                <SingleImagePanel imgSource={packagingOiro6}/>
                <ThreeImagePanel 
                    imgSourceMiddle={packagingOiro7}
                    imgSourceRight={packagingOiro8}
                    additionalStyle="lastPanelMarginBottom"/>
                <ProjectTextPanel
                    projectTitle="titleCaleOiro"
                    projectText="bodyCaleOiro"
                    projectItems="pageItemsCaleOiro"
                    projectFotoText="none"
                    navLeftLabel="titleCasamentoSS"
                    navRightLabel="titleSet"
                    navigateTo={this.props.navigateTo}
                    leftNavUrl={Utils.grafemaUrls.casamentoSSProject}
                    rightNavUrl={Utils.grafemaUrls.setProject}
                />
            </div>
        );
    }
}

export default PackagingCaleOiroProj;