import React from 'react';

import './../../style/desktopLanding.css';
import './../../style/mobileLanding.css';

import grafema from './../../assets/grafema.png'

import { FormattedMessage } from "react-intl";
import * as Utils from './../../utils/utils.js';

class Header extends React.Component {

    constructor(props) {
      super(props);
          
    }
  
    render() {
      
      return(
        <div className="headerContainer">
          <div className="logoContainer">
          <img src={grafema} className="logoImage" onClick={() => this.props.navigateTo(Utils.grafemaUrls.landingPage)}/>
          </div>
          <div className="navMenu">
            <ul className="horizontal-list">
              <li className={this.props.projectStyle} onClick={() => this.props.navigateTo(Utils.grafemaUrls.landingPage)}><FormattedMessage id="project"/></li>
              <li className={this.props.studioStyle} onClick={() => this.props.navigateTo(Utils.grafemaUrls.studioPage)}><FormattedMessage id="studio"/></li>
              <li className={this.props.ptStyle} onClick={e => this.props.switchLanguageClick('pt')}>pt</li>
              <li className={this.props.enStyle} onClick={e => this.props.switchLanguageClick('en')}>en</li>
            </ul>
          </div>
        </div>
      );
    }
  }
  
  class StickyHeader extends React.Component {
    
    constructor(props) {
      super(props);
          
      this.state = {
        opacity: '0',
        zindex: '-10',
        curScrollPos: 0
      }
  
      this.windowScrollEvent = this.windowScrollEvent.bind(this);
    }
    
    windowScrollEvent() { 
      
      let currentScrollPos = window.pageYOffset;
      //console.log(currentScrollPos)
      if(currentScrollPos < 80) {
        
        this.state.curScrollPos = currentScrollPos;
        this.setState({ opacity: "0" })
        this.setState({ zindex: "-10" })
        return;
      }
      if (currentScrollPos < this.state.curScrollPos) {
        
        this.state.curScrollPos = currentScrollPos;
        this.state.curScrollPos = currentScrollPos;
        this.setState({ opacity: "1" })
        this.setState({ zindex: "10" })
      } 
      else {
        
        this.state.curScrollPos = currentScrollPos;
        this.setState({ opacity: "0" })
        this.setState({ zindex: "-10" })
      }
    }
  
    componentDidMount() {
      
      if (typeof window !== "undefined") {
        
        window.addEventListener("scroll", this.windowScrollEvent);
      }
    }
  
    componentWillUnmount() {
  
      window.removeEventListener('scroll', this.windowScrollEvent);
    }
  
    render() {
  
      var menuLabelClass = "";
      return(
        <div className="stickyHeaderContainerWrapper" style={{ opacity: `${this.state.opacity}`, zIndex: `${this.state.zindex}`}}>
          <div className="stickyHeaderContainer">
            <div className="logoContainer" >
              <img src={grafema} className="logoImage" onClick={() => this.props.navigateTo(Utils.grafemaUrls.landingPage)}/>
            </div>
            <div className="navMenu">
              <ul className="horizontal-list">
                <li className={this.props.projectStyle} onClick={() => this.props.navigateTo(Utils.grafemaUrls.landingPage)}><FormattedMessage id="project"/></li>
                <li className={this.props.studioStyle} onClick={() => this.props.navigateTo(Utils.grafemaUrls.studioPage)}><FormattedMessage id="studio"/></li>
                <li className={this.props.ptStyle} onClick={e => this.props.switchLanguageClick('pt')}>pt</li>
                <li className={this.props.enStyle} onClick={e => this.props.switchLanguageClick('en')}>en</li>
              </ul>
            </div>
          </div>
        </div>
      );
    }
  }

  export {Header, StickyHeader};