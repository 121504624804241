import React from 'react';

import pilates1 from './../../assets/pilates/grafema_pilates_01.jpg'
import pilates2 from './../../assets/pilates/grafema_pilates_02.jpg'
import pilates3 from './../../assets/pilates/grafema_pilates_03.mp4'
import pilates4 from './../../assets/pilates/grafema_pilates_04.jpg'
import pilates5 from './../../assets/pilates/grafema_pilates_05.jpg'
import pilates6 from './../../assets/pilates/grafema_pilates_06.jpg'
import pilates7 from './../../assets/pilates/grafema_pilates_07.jpg'
import pilates8 from './../../assets/pilates/grafema_pilates_08.jpg'
import pilates9 from './../../assets/pilates/grafema_pilates_09.jpg'
import pilates10 from './../../assets/pilates/grafema_pilates_10.jpg'
import pilates11 from './../../assets/pilates/grafema_pilates_11.jpg'
import pilates12 from './../../assets/pilates/grafema_pilates_12.jpg'
import pilates13 from './../../assets/pilates/grafema_pilates_13.jpg'


import {SingleImagePanel, ThreeImagePanel, ProjectTextPanel} from './../componentUtils/projectImgPanel.js'
import * as Utils from './../../utils/utils.js';

class PilatesProj extends React.Component {

    render() {
    
        return (
            <div>
                <ThreeImagePanel 
                    imgSourceLeft={pilates1}
                    imgSourceMiddle={pilates2}/>
                <SingleImagePanel imgSource={pilates3}/>
                <SingleImagePanel imgSource={pilates4}/>
                <ThreeImagePanel 
                    imgSourceLeft={pilates5}
                    imgSourceRight={pilates6}/>
                <SingleImagePanel imgSource={pilates7}/>
                <ThreeImagePanel 
                    imgSourceLeft={pilates8}
                    imgSourceMiddle={pilates9}/>
                <SingleImagePanel imgSource={pilates10}/>
                <ThreeImagePanel 
                    imgSourceMiddle={pilates11}
                    imgSourceRight={pilates12}/>
                <SingleImagePanel imgSource={pilates13}
                    additionalStyle="lastPanelMarginBottom"/>

                <ProjectTextPanel
                    projectTitle="titlePilates"
                    projectText="bodyPilates"
                    projectItems="pageItemsPilates"
                    projectFotoText="fotoTextPilates"
                    fotoUrl="https://www.instagram.com/mariliamm.fotografia/"
                    fotoGrafema={true}
                    navLeftLabel="titleCampanhaUA"
                    navRightLabel="titleMartini"
                    navigateTo={this.props.navigateTo}
                    leftNavUrl={Utils.grafemaUrls.campanhaUAProject}
                    rightNavUrl={Utils.grafemaUrls.martiniProject}
                />
            </div>
        );
    }
}

export default PilatesProj;