import React from 'react';

import coimbra1 from './../../assets/coimbra-2027/grafema_coimbra-2027-contest_01.mp4'
import coimbra2 from './../../assets/coimbra-2027/grafema_coimbra-2027-contest_02.jpg'
import coimbra3 from './../../assets/coimbra-2027/grafema_coimbra-2027-contest_03.jpg'
import coimbra4 from './../../assets/coimbra-2027/grafema_coimbra-2027-contest_04.jpg'
import coimbra5 from './../../assets/coimbra-2027/grafema_coimbra-2027-contest_05.mp4'
import coimbra6 from './../../assets/coimbra-2027/grafema_coimbra-2027-contest_06.jpg'
import coimbra7 from './../../assets/coimbra-2027/grafema_coimbra-2027-contest_07.jpg'
import coimbra8 from './../../assets/coimbra-2027/grafema_coimbra-2027-contest_08.mp4'
import coimbra9 from './../../assets/coimbra-2027/grafema_coimbra-2027-contest_09.jpg'
import coimbra10 from './../../assets/coimbra-2027/grafema_coimbra-2027-contest_10.jpg'
import coimbra11 from './../../assets/coimbra-2027/grafema_coimbra-2027-contest_11.jpg'
import coimbra12 from './../../assets/coimbra-2027/grafema_coimbra-2027-contest_12.jpg'
import coimbra13 from './../../assets/coimbra-2027/grafema_coimbra-2027-contest_13.jpg'
import coimbra14 from './../../assets/coimbra-2027/grafema_coimbra-2027-contest_14.jpg'
import coimbra15 from './../../assets/coimbra-2027/grafema_coimbra-2027-contest_15.jpg'
import coimbra16 from './../../assets/coimbra-2027/grafema_coimbra-2027-contest_16.jpg'
import coimbra17 from './../../assets/coimbra-2027/grafema_coimbra-2027-contest_17.jpg'
import coimbra18 from './../../assets/coimbra-2027/grafema_coimbra-2027-contest_18.jpg'
import coimbra19 from './../../assets/coimbra-2027/grafema_coimbra-2027-contest_19.jpg'
import coimbra20 from './../../assets/coimbra-2027/grafema_coimbra-2027-contest_20.jpg'
import coimbra21 from './../../assets/coimbra-2027/grafema_coimbra-2027-contest_21.jpg'


import {SingleImagePanel, ThreeImagePanel, ProjectTextPanel} from './../componentUtils/projectImgPanel.js'
import * as Utils from './../../utils/utils.js';

class CoimbraProj extends React.Component {

    render() {
    
        return (
            <div>
                <SingleImagePanel imgSource={coimbra1}/>
                <SingleImagePanel imgSource={coimbra2}/>
                <SingleImagePanel imgSource={coimbra3}/>
                <SingleImagePanel imgSource={coimbra4}/>
                <ThreeImagePanel 
                    imgSourceLeft={coimbra5}
                    imgSourceMiddle={coimbra6}/>
                <SingleImagePanel imgSource={coimbra7}/>
                <SingleImagePanel imgSource={coimbra8}/>
                <SingleImagePanel imgSource={coimbra9}/>
                <ThreeImagePanel 
                    imgSourceLeft={coimbra11}
                    imgSourceRight={coimbra10}/>
                <SingleImagePanel imgSource={coimbra12}/>
                <SingleImagePanel imgSource={coimbra13}/>
                <SingleImagePanel imgSource={coimbra14}/>
                <SingleImagePanel imgSource={coimbra15}/>
                <ThreeImagePanel 
                    imgSourceLeft={coimbra16}
                    imgSourceRight={coimbra17}/>
                <SingleImagePanel imgSource={coimbra18}/>
                <SingleImagePanel imgSource={coimbra19}/>
                <SingleImagePanel imgSource={coimbra20}/>
                <SingleImagePanel imgSource={coimbra21}
                    additionalStyle="lastPanelMarginBottom"/>               
                    
                <ProjectTextPanel
                    projectTitle="titleCoimbra"
                    projectText="bodyCoimbra"
                    projectFotoText="none"
                    projectItems="pageItemsCoimbra"
                    navLeftLabel="titleAngura"
                    navRightLabel="titleRectaIntencaoBook"
                    navigateTo={this.props.navigateTo}
                    leftNavUrl={Utils.grafemaUrls.anguraProject}
                    rightNavUrl={Utils.grafemaUrls.rectaIntencaoBookProject}
                />
            </div>
        );
    }
}

export default CoimbraProj;