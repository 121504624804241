import React from 'react';

import './../style/desktopLanding.css';
import './../style/mobileLanding.css';

import armazemAlfandega from './../assets/landing/ArmazemAlfandega4.jpg'
import creativityVirus1 from './../assets/landing/CreativityVirus1.jpg'
import minhaCasa14 from './../assets/landing/grafema_minha-casa_11.jpg'
import gimTonico from './../assets/landing/Gimtonico.mp4'
import first from './../assets/landing/First.mp4'
import desdobravel from './../assets/landing/Desdobravel1.jpg'
import illustration1 from './../assets/landing/Illustration1.jpg'
import pilates from './../assets/landing/Pilates.mp4'
import martini from './../assets/landing/7.jpg'
import designFactory from './../assets/landing/DesignFactory.gif'
import nutrana from './../assets/landing/grafema_nutrana_11.jpg'
import maisIdentity from './../assets/landing/+-identity4.jpg'
import wedding from './../assets/landing/Wedding7.jpg'
import chocolate from './../assets/landing/Chocolate.jpg'
import secondliferesin from './../assets/landing/grafema_2ndliferesin_17.mp4'
import angura from './../assets/landing/grafema_angura_04.jpg'
import coimbra from './../assets/landing/grafema_coimbra-2027-contest_21.jpg'
import reactbook from './../assets/landing/grafema_recta-intencao-book_01.jpg'
import severe from './../assets/landing/moldura-1-NOVO-site.mp4'
import clivitalbio from './../assets/landing/grafema_clivitalbio_16.jpg'
import amcc from './../assets/landing/grafema_amcc_01.mp4'
import ciceco from './../assets/landing/grafema_ciceco_01.mp4'
import essence from './../assets/landing/grafema_the-essence-collective_03.jpg'
import set from './../assets/landing/grafema_SET_04.jpg'
import capitolio from './../assets/landing/grafema_capitolio_01.mp4'
import ummi from './../assets/landing/grafema_ummi_14.jpg'


import * as Utils from './../utils/utils.js'
import { FormattedMessage } from "react-intl";

class TwoImagePanelStd extends React.Component {
  
  constructor(props) {
    super(props);
    this.myRefLeft = React.createRef();
    this.myRefRight = React.createRef();

    this.windowScrollEvent = this.windowScrollEvent.bind(this);
    // console.log(this.props);
  }

  componentDidMount() {
    
    if (typeof window !== "undefined") {
      
      window.addEventListener("scroll", this.windowScrollEvent);

      // window.addEventListener("scroll", (event) => {
        
      //   this.myRefLeft.current.style.opacity = 
      //     Utils.computeOpacityHelper(this.myRefLeft.current.getBoundingClientRect());
      //   this.myRefRight.current.style.opacity = 
      //     Utils.computeOpacityHelper(this.myRefRight.current.getBoundingClientRect());
      // });
    }
  }

  componentWillUnmount() {

    window.removeEventListener('scroll', this.windowScrollEvent);
  }

  windowScrollEvent() {
    
    this.myRefLeft.current.style.opacity = 
      Utils.computeOpacityHelperLanding(this.myRefLeft.current.getBoundingClientRect());
    this.myRefRight.current.style.opacity = 
      Utils.computeOpacityHelperLanding(this.myRefRight.current.getBoundingClientRect());
  }

  renderElement(internalSource, internalStyle) {

    if(internalSource.includes("mp4"))
      return this.renderVideo(internalSource, internalStyle)
    else
      return this.renderImage(internalSource, internalStyle);
  }

  renderVideo(internalSource, internalStyle) {
    
    return (
      <video src={internalSource} className={internalStyle} playsInline autoPlay muted loop/>
    );
  }

  renderImage(internalSource, internalStyle) {

    return (
      <img src={internalSource} className={internalStyle}/>
    );
  }

  render() {

    return(
      <div className={"twoImagePanel_Container " + this.props.panelMargin}>
        <div className="twoImagePanel_ImgContainer">
          <div ref={this.myRefLeft} className="project" onClick={() => this.props.handleProjectClick(this.props.leftLink)}>
            {this.renderElement(this.props.imgSourceLeft, this.props.imgStyleLeft)}
            <div className="twoImagePanel_LabelsContainer">
              <div className={"twoImagePanel_LeftLabel " + 'projectLabel'}>
                <FormattedMessage id={this.props.imgCaptionLeft}/>
              </div>
            </div>
          </div>
          <div ref={this.myRefRight} className={"twoImagePanel_RightImage project"} onClick={() => this.props.handleProjectClick(this.props.rightLink)}>
            {this.renderElement(this.props.imgSourceRight, this.props.imgStyleRight)}
            <div className="twoImagePanel_LabelsContainer">
              <div className={"twoImagePanel_RightLabel " + this.props.imgStyleRight + " " + 'projectLabel'}>
                <FormattedMessage id={this.props.imgCaptionRight}/>
              </div>
            </div>
          </div>
        </div>
      </div> 
    );
  }
}

class OneImageRightPanel extends React.Component {
  
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    
    this.windowScrollEvent = this.windowScrollEvent.bind(this);
  }

  componentDidMount() {
    
    if (typeof window !== "undefined") {
      
      window.addEventListener("scroll", this.windowScrollEvent);
    }
  }

  componentWillUnmount() {

    window.removeEventListener('scroll', this.windowScrollEvent);
  }

  windowScrollEvent() {
    
    let boundingRect = this.myRef.current.getBoundingClientRect();
    this.myRef.current.style.opacity = Utils.computeOpacityHelperLanding(boundingRect);
  }

  renderElement(internalSource, internalStyle) {

    if(internalSource.includes("mp4"))
      return this.renderVideo(internalSource, internalStyle)
    else
      return this.renderImage(internalSource, internalStyle);
  }

  renderVideo(internalSource, internalStyle) {
    
    return (
      <video src={internalSource} className={internalStyle} playsInline autoPlay muted loop/>
    );
  }

  renderImage(internalSource, internalStyle) {

    return (
      <img src={internalSource} className={internalStyle}/>
    );
  }

  render() {
    
    return(
      
      <div ref={this.myRef} className={"OneImageRightPanel_Container project " + this.props.panelMargin} onClick={() => this.props.handleProjectClick(this.props.link)}>
          {this.renderElement(this.props.imgSource, this.props.imgStyle)}
          <div className={'OneImageRightPanel_LabelContainer ' + this.props.imgStyle} >
            <div className={'OneImageRightPanel_Label projectLabel'}>
              <FormattedMessage id={this.props.imgCaption}/>
            </div>
          </div>
      </div>
      
    );
  }
}

class OneImageLeftPanel extends React.Component {
  
  constructor(props) {
    super(props);
    this.myRef = React.createRef();

    this.windowScrollEvent = this.windowScrollEvent.bind(this);
  }

  componentDidMount() {
    
    if (typeof window !== "undefined") {
      
      window.addEventListener("scroll", this.windowScrollEvent);
    }
  }

  componentWillUnmount() {

    window.removeEventListener('scroll', this.windowScrollEvent);
  }

  windowScrollEvent() {
    
    let boundingRect = this.myRef.current.getBoundingClientRect();
    this.myRef.current.style.opacity = Utils.computeOpacityHelperLanding(boundingRect);
  }

  renderElement(internalSource, internalStyle) {

    if(internalSource.includes("mp4"))
      return this.renderVideo(internalSource, internalStyle)
    else
      return this.renderImage(internalSource, internalStyle);
  }

  renderVideo(internalSource, internalStyle) {
    
    return (
      <video src={internalSource} className={internalStyle} playsInline autoPlay muted loop/>
    );
  }

  renderImage(internalSource, internalStyle) {

    return (
      <img src={internalSource} className={internalStyle}/>
    );
  }

  render() {
    
    return(
      
      <div ref={this.myRef} className={"OneImageLeftPanel_Container project " + this.props.panelMargin} onClick={() => this.props.handleProjectClick(this.props.link)}>
          {this.renderElement(this.props.imgSource, this.props.imgStyle)}
          <div className={'OneImageLeftPanel_LabelContainer ' + this.props.imgStyle} >
            <div className={'OneImageLeftPanel_Label projectLabel'}>
              <FormattedMessage id={this.props.imgCaption}/>
            </div>
          </div>
      </div>
      
    );
  }
}

class TwoImagePanelSpecial extends React.Component {
  
  constructor(props) {
    super(props);
    this.myRefLeft = React.createRef();
    this.myRefRight = React.createRef();

    this.windowScrollEvent = this.windowScrollEvent.bind(this);
  }

  componentDidMount() {
    
    if (typeof window !== "undefined") {
      
      window.addEventListener("scroll", this.windowScrollEvent);
    }
  }

  componentWillUnmount() {

    window.removeEventListener('scroll', this.windowScrollEvent);
  }

  windowScrollEvent() {
    
    //let currentScrollPos = window.pageYOffset;
    //console.log(this.myRef.current.getBoundingClientRect());
    this.myRefLeft.current.style.opacity = 
      Utils.computeOpacityHelperLanding(this.myRefLeft.current.getBoundingClientRect());
    this.myRefRight.current.style.opacity = 
      Utils.computeOpacityHelperLanding(this.myRefRight.current.getBoundingClientRect());
  }

  renderElement(internalSource, internalStyle) {

    if(internalSource.includes("mp4"))
      return this.renderVideo(internalSource, internalStyle)
    else
      return this.renderImage(internalSource, internalStyle);
  }

  renderVideo(internalSource, internalStyle) {
    
    return (
      <video src={internalSource} className={internalStyle} playsInline autoPlay muted loop/>
    );
  }

  renderImage(internalSource, internalStyle) {

    return (
      <img src={internalSource} className={internalStyle}/>
    );
  }

  render() {
    return(
      <div className={"twoImagePanel_Container " + this.props.panelMargin}>
        <div className="twoImagePanel_ImgContainer">
          <div className="twoImagePanelSpecial_HiddenImage_ImgContainer">
            <div ref={this.myRefLeft} className="twoImagePanelSpecial_LeftImage project" onClick={() => this.props.handleProjectClick(this.props.leftLink)}>
              {this.renderElement(this.props.imgSourceLeft, this.props.imgStyleLeft)}
              <div className="twoImagePanel_LabelsContainer">
                <div className="twoImagePanel_LeftLabel projectLabel">
                  <FormattedMessage id={this.props.imgCaptionLeft}/>
                </div>
              </div>
            </div>
            <img src={this.props.imgSourceHidden} className={this.props.imgStyleRight + " twoImagePanelSpecial_HiddenImage"}/>
          </div>
          <div ref={this.myRefRight} className={"twoImagePanelSpecial_RightImage project"} onClick={() => this.props.handleProjectClick(this.props.rightLink)}>
            {this.renderElement(this.props.imgSourceRight, this.props.imgStyleRight)}
            <div className="twoImagePanel_LabelsContainer">
              <div className={"twoImagePanel_RightLabel " + this.props.imgStyleRight + " projectLabel"}>
                <FormattedMessage id={this.props.imgCaptionRight}/>
              </div>
            </div>
          </div>
        </div>
        
      </div>
      
    );
  }
}


class LandingPage extends React.Component {

  constructor(props) {
    super(props);
  }


  render() {
    
    return (
      <div>
        <TwoImagePanelStd
          imgSourceLeft={set}
          imgSourceRight={capitolio}
          imgStyleLeft="setLandingImgSize"
          imgStyleRight="capitolioImgSize"
          imgCaptionLeft="titleSet"
          imgCaptionRight="titleCapitolio"
          panelMargin="normalPanellMarginBottom"
          handleProjectClick={this.props.navigateTo}
          leftLink = {Utils.grafemaUrls.setProject}
          rightLink = {Utils.grafemaUrls.capitolioProject}
        />
        <OneImageRightPanel 
          elmtID = "ummi"
          imgSource={ummi}
          imgStyle="ummiImgSize"
          imgCaption="titleUmmi"
          panelMargin="normalPanellMarginBottom"
          handleProjectClick={this.props.navigateTo}
          link= {Utils.grafemaUrls.ummitProject}
        />
        <TwoImagePanelStd
          imgSourceLeft={essence}
          imgSourceRight={ciceco}
          imgStyleLeft="essenceCollectiveLandingImgSize"
          imgStyleRight="cicecoImgSize"
          imgCaptionLeft="titleEssenceCol"
          imgCaptionRight="titleCiceo"
          panelMargin="normalPanellMarginBottom"
          handleProjectClick={this.props.navigateTo}
          leftLink = {Utils.grafemaUrls.essenceColProject}
          rightLink = {Utils.grafemaUrls.cicecoProject}
        />
         <OneImageLeftPanel 
          imgSource={amcc}
          imgStyle="amccImgSize"
          imgCaption="titleAmcc"
          panelMargin="normalPanellMarginBottom"
          handleProjectClick={this.props.navigateTo}
          link= {Utils.grafemaUrls.amccProject}
        />      
        <TwoImagePanelSpecial 
          imgSourceLeft={clivitalbio}
          imgSourceRight={secondliferesin}
          imgSourceHidden={illustration1}
          imgStyleLeft="clivitalbioLandingImgSize"
          imgStyleRight="secondliferesinLandingImgSize"
          imgCaptionLeft="titleclivitalbio"
          imgCaptionRight="titleSecondLiferesin"
          panelMargin="normalPanellMarginBottom"
          handleProjectClick={this.props.navigateTo}
          leftLink = {Utils.grafemaUrls.clivitalbioProject}
          rightLink = {Utils.grafemaUrls.secondliferesinProject}
        />
        <OneImageRightPanel 
          elmtID = "angura"
          imgSource={angura}
          imgStyle="anguraLandingImgSize"
          imgCaption="titleAngura"
          panelMargin="normalPanellMarginBottom"
          handleProjectClick={this.props.navigateTo}
          link= {Utils.grafemaUrls.anguraProject}
        />
        <TwoImagePanelSpecial 
          imgSourceLeft={coimbra}
          imgSourceRight={reactbook}
          imgSourceHidden={reactbook}
          imgStyleLeft="coimbraLandingImgSize"
          imgStyleRight="reactbookandingImgSize"
          imgCaptionLeft="titleCoimbra"
          imgCaptionRight="titleRectaIntencaoBook"
          panelMargin="normalPanellMarginBottom"
          handleProjectClick={this.props.navigateTo}
          leftLink = {Utils.grafemaUrls.coimbraProject}
          rightLink = {Utils.grafemaUrls.rectaIntencaoBookProject}
        />
        <OneImageLeftPanel 
          imgSource={severe}
          imgStyle="severeImgSize"
          imgCaption="titleSevere"
          panelMargin="normalPanellMarginBottom"
          handleProjectClick={this.props.navigateTo}
          link= {Utils.grafemaUrls.severeProject}
        />        
        <TwoImagePanelStd
          imgSourceLeft={armazemAlfandega}
          imgSourceRight={gimTonico}
          imgStyleLeft="grafemaLandingImgSize"
          imgStyleRight="gimTonicoLandingImgSize"
          imgCaptionLeft="titleArmazem"
          imgCaptionRight="titleGim"
          panelMargin="normalPanellMarginBottom"
          handleProjectClick={this.props.navigateTo}
          leftLink = {Utils.grafemaUrls.armazemProject}
          rightLink = {Utils.grafemaUrls.gimTonicoProject}
        />
        <OneImageRightPanel 
          elmtID = "creativityVirus"
          imgSource={creativityVirus1}
          imgStyle="creativityVirus1LandingImgSize"
          imgCaption="titleVirus"
          panelMargin="normalPanellMarginBottom"
          handleProjectClick={this.props.navigateTo}
          link= {Utils.grafemaUrls.virusProject}
        />
        <TwoImagePanelStd 
          imgSourceLeft={minhaCasa14}
          imgSourceRight={first}
          imgStyleLeft="minhaCasa14LandingImgSize"
          imgStyleRight="firstLandingImgSize"
          imgCaptionLeft="titleMinhaCasa"
          imgCaptionRight="titlePCI"
          panelMargin="normalPanellMarginBottom"
          handleProjectClick={this.props.navigateTo}
          leftLink = {Utils.grafemaUrls.minhaCasaProject}
          rightLink = {Utils.grafemaUrls.pciProject}
        />
        <OneImageLeftPanel 
          imgSource={desdobravel}
          imgStyle="desdobravelLandingImgSize"
          imgCaption="titleCale"
          panelMargin="normalPanellMarginBottom"
          handleProjectClick={this.props.navigateTo}
          link= {Utils.grafemaUrls.caleProject}
        />
        <TwoImagePanelSpecial 
          imgSourceLeft={illustration1}
          imgSourceRight={pilates}
          imgSourceHidden={illustration1}
          imgStyleLeft="illustrationLandingImgSize"
          imgStyleRight="pilatesLandingImgSize"
          imgCaptionLeft="titleCampanhaUA"
          imgCaptionRight="titlePilates"
          panelMargin="normalPanellMarginBottom"
          handleProjectClick={this.props.navigateTo}
          leftLink = {Utils.grafemaUrls.campanhaUAProject}
          rightLink = {Utils.grafemaUrls.pilatesProject}
        />
        <OneImageRightPanel 
          elmtID = "martini"
          imgSource={martini}
          imgStyle="martiniLandingImgSize"
          imgCaption="titleMartini"
          panelMargin="normalPanellMarginBottom"
          handleProjectClick={this.props.navigateTo}
          link= {Utils.grafemaUrls.martiniProject}
        />
        <TwoImagePanelSpecial 
          imgSourceLeft={designFactory}
          imgSourceRight={nutrana}
          imgSourceHidden={nutrana}
          imgStyleLeft="designFactoryLandingImgSize"
          imgStyleRight="nutranaLandingImgSize"
          imgCaptionLeft="titleDesignFactory"
          imgCaptionRight="titleNutrana"
          panelMargin="normalPanellMarginBottom"
          handleProjectClick={this.props.navigateTo}
          leftLink = {Utils.grafemaUrls.designFactoryProject}
          rightLink = {Utils.grafemaUrls.nutranaProject}
        />
        <OneImageLeftPanel 
          imgSource={maisIdentity}
          imgStyle="maisLandingIdentity"
          imgCaption="titleMaisMenos"
          panelMargin="normalPanellMarginBottom"
          handleProjectClick={this.props.navigateTo}
          link= {Utils.grafemaUrls.maisMenosProject}
        />
        <TwoImagePanelStd 
          imgSourceLeft={wedding}
          imgSourceRight={chocolate}
          imgStyleLeft="weddingLandingImgSize"
          imgStyleRight="chocolateLandingImgSize"
          imgCaptionLeft="titleCasamentoSS"
          imgCaptionRight="titleCaleOiro"
          panelMargin="lastPanelMarginBottom"
          handleProjectClick={this.props.navigateTo}
          leftLink = {Utils.grafemaUrls.casamentoSSProject}
          rightLink = {Utils.grafemaUrls.caleOiroPackageProject}
        />
      </div>
    );
  }
}

// ========================================

export default LandingPage;