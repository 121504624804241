import React from 'react';

import virus1 from './../../assets/virus/grafema_the-creativity-virus_01.jpg'
import virus2 from './../../assets/virus/grafema_the-creativity-virus_02.jpg'
import virus3 from './../../assets/virus/grafema_the-creativity-virus_03.jpg'
import virus4 from './../../assets/virus/grafema_the-creativity-virus_04.jpg'
import virus5 from './../../assets/virus/grafema_the-creativity-virus_05.jpg'
import virus6 from './../../assets/virus/grafema_the-creativity-virus_06.jpg'
import virus7 from './../../assets/virus/grafema_the-creativity-virus_07.jpg'
import virus8 from './../../assets/virus/grafema_the-creativity-virus_08.jpg'
import virus9 from './../../assets/virus/grafema_the-creativity-virus_09.mp4'
import virus10 from './../../assets/virus/grafema_the-creativity-virus_10.jpg'
import virus11 from './../../assets/virus/grafema_the-creativity-virus_11.jpg'
import virus12 from './../../assets/virus/grafema_the-creativity-virus_12.jpg'
import virus13 from './../../assets/virus/grafema_the-creativity-virus_13.jpg'
import virus14 from './../../assets/virus/grafema_the-creativity-virus_14.jpg'
import virus15 from './../../assets/virus/grafema_the-creativity-virus_15.jpg'
import virus16 from './../../assets/virus/grafema_the-creativity-virus_16.jpg'
import virus17 from './../../assets/virus/grafema_the-creativity-virus_17.jpg'
import virus18 from './../../assets/virus/grafema_the-creativity-virus_18.jpg'


import {SingleImagePanel, ThreeImagePanel, ProjectTextPanel} from './../componentUtils/projectImgPanel.js'
import * as Utils from './../../utils/utils.js';

class VirusProj extends React.Component {

    render() {
    
        return (
            <div>
                <SingleImagePanel imgSource={virus1}/>
                <ThreeImagePanel 
                    imgSourceLeft={virus2}
                    imgSourceMiddle={virus3}/>
                <SingleImagePanel imgSource={virus4}/>
                <SingleImagePanel imgSource={virus5}/>
                <ThreeImagePanel 
                    imgSourceMiddle={virus6}
                    imgSourceRight={virus7}/>
                <SingleImagePanel imgSource={virus8}/>
                <SingleImagePanel imgSource={virus9}/>
                <SingleImagePanel imgSource={virus10}/>
                <SingleImagePanel imgSource={virus11}/>
                <SingleImagePanel imgSource={virus12}/>
                <ThreeImagePanel 
                    imgSourceLeft={virus13}
                    imgSourceMiddle={virus14}/>
                <SingleImagePanel imgSource={virus15}/>
                <SingleImagePanel imgSource={virus16}/>
                <ThreeImagePanel 
                    imgSourceLeft={virus17}
                    imgSourceMiddle={virus18}
                    additionalStyle="lastPanelMarginBottom"/>
                <ProjectTextPanel
                    projectTitle="titleVirus"
                    projectText="bodyVirus"
                    projectFotoText="fotoTextVirus"
                    fotoUrl="https://www.fotografamos.com"
                    fotoGrafema={true}
                    projectItems="pageItemsVirus"
                    navLeftLabel="titleGim"
                    navRightLabel="titleMinhaCasa"
                    navigateTo={this.props.navigateTo}
                    leftNavUrl={Utils.grafemaUrls.gimTonicoProject}
                    rightNavUrl={Utils.grafemaUrls.minhaCasaProject}
                />
            </div>
        );
    }
}

export default VirusProj;