import React from 'react';

import secondliferesin1 from './../../assets/2ndliferesin/grafema_2ndliferesin_01.mp4'
import secondliferesin2 from './../../assets/2ndliferesin/grafema_2ndliferesin_02.jpg'
import secondliferesin3 from './../../assets/2ndliferesin/grafema_2ndliferesin_03.jpg'
import secondliferesin4 from './../../assets/2ndliferesin/grafema_2ndliferesin_04.jpg'
import secondliferesin5 from './../../assets/2ndliferesin/grafema_2ndliferesin_05.jpg'
import secondliferesin6 from './../../assets/2ndliferesin/grafema_2ndliferesin_06.jpg'
import secondliferesin7 from './../../assets/2ndliferesin/grafema_2ndliferesin_07.mp4'
import secondliferesin8 from './../../assets/2ndliferesin/grafema_2ndliferesin_08.jpg'
import secondliferesin9 from './../../assets/2ndliferesin/grafema_2ndliferesin_09.jpg'
import secondliferesin10 from './../../assets/2ndliferesin/grafema_2ndliferesin_10.mp4'
import secondliferesin11 from './../../assets/2ndliferesin/grafema_2ndliferesin_11.jpg'
import secondliferesin12 from './../../assets/2ndliferesin/grafema_2ndliferesin_12.jpg'
import secondliferesin13 from './../../assets/2ndliferesin/grafema_2ndliferesin_13.jpg'
import secondliferesin14 from './../../assets/2ndliferesin/grafema_2ndliferesin_14.jpg'
import secondliferesin15 from './../../assets/2ndliferesin/grafema_2ndliferesin_15.jpg'
import secondliferesin16 from './../../assets/2ndliferesin/grafema_2ndliferesin_16.jpg'
import secondliferesin17 from './../../assets/2ndliferesin/grafema_2ndliferesin_17.mp4'


import {SingleImagePanel, ThreeImagePanel, ProjectTextPanel} from './../componentUtils/projectImgPanel.js'
import * as Utils from './../../utils/utils.js';

class SecondLiferesinProj extends React.Component {

    render() {
    
        return (
            <div>
                <SingleImagePanel imgSource={secondliferesin1}/>
                <ThreeImagePanel 
                    imgSourceLeft={secondliferesin2}
                    imgSourceMiddle={secondliferesin3}/>
                <SingleImagePanel imgSource={secondliferesin4}/>
                <SingleImagePanel imgSource={secondliferesin5}/>
                <ThreeImagePanel 
                    imgSourceMiddle={secondliferesin6}
                    imgSourceRight={secondliferesin7}/>
                <SingleImagePanel imgSource={secondliferesin8}/>
                <SingleImagePanel imgSource={secondliferesin9}/>
                <SingleImagePanel imgSource={secondliferesin10}/>
                <ThreeImagePanel 
                    imgSourceLeft={secondliferesin11}
                    imgSourceRight={secondliferesin12}/>
                <SingleImagePanel imgSource={secondliferesin13}/>
                <SingleImagePanel imgSource={secondliferesin14}/>
                <SingleImagePanel imgSource={secondliferesin15}/>
                <SingleImagePanel imgSource={secondliferesin16}
                    additionalStyle="lastPanelMarginBottom"/>
                    
                <ProjectTextPanel
                    projectTitle="titleSecondLiferesin"
                    projectText="bodySecondLiferesin"
                    projectFotoText="none"
                    projectItems="pageItemsSecondLiferesin"
                    navLeftLabel="titleclivitalbio"
                    navRightLabel="titleAngura"
                    navigateTo={this.props.navigateTo}
                    leftNavUrl={Utils.grafemaUrls.clivitalbioProject}
                    rightNavUrl={Utils.grafemaUrls.anguraProject}
                />
            </div>
        );
    }
}

export default SecondLiferesinProj;