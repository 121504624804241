import React from 'react';

import ummi1 from './../../assets/ummi/grafema_ummi_01.jpg'
import ummi2 from './../../assets/ummi/grafema_ummi_02.jpg'
import ummi3 from './../../assets/ummi/grafema_ummi_03.jpg'
import ummi4 from './../../assets/ummi/grafema_ummi_04.jpg'
import ummi5 from './../../assets/ummi/grafema_ummi_05.jpg'
import ummi6 from './../../assets/ummi/grafema_ummi_06.jpg'
import ummi7 from './../../assets/ummi/grafema_ummi_07.jpg'
import ummi8 from './../../assets/ummi/grafema_ummi_08.jpg'
import ummi9 from './../../assets/ummi/grafema_ummi_09.jpg'
import ummi10 from './../../assets/ummi/grafema_ummi_10.jpg'
import ummi11 from './../../assets/ummi/grafema_ummi_11.jpg'
import ummi12 from './../../assets/ummi/grafema_ummi_12.jpg'
import ummi13 from './../../assets/ummi/grafema_ummi_13.jpg'
import ummi14 from './../../assets/ummi/grafema_ummi_14.jpg'
import ummi15 from './../../assets/ummi/grafema_ummi_15.jpg'
import ummi16 from './../../assets/ummi/grafema_ummi_16.jpg'
import ummi17 from './../../assets/ummi/grafema_ummi_17.jpg'
import ummi18 from './../../assets/ummi/grafema_ummi_18.jpg'
import ummi19 from './../../assets/ummi/grafema_ummi_19.jpg'
import ummi20 from './../../assets/ummi/grafema_ummi_20.jpg'
import ummi21 from './../../assets/ummi/grafema_ummi_21.mp4'
import ummi22 from './../../assets/ummi/grafema_ummi_22.jpg'
import ummi23 from './../../assets/ummi/grafema_ummi_23.jpg'

import {SingleImagePanel, ThreeImagePanel, ProjectTextPanel} from './../componentUtils/projectImgPanel.js'
import * as Utils from './../../utils/utils.js';

class UmmiProj extends React.Component {

    render() {
    
        return (
            <div>
                <SingleImagePanel imgSource={ummi1}/>
                <ThreeImagePanel 
                    imgSourceLeft={ummi2}
                    imgSourceRight={ummi3}/>
                <SingleImagePanel imgSource={ummi4}/>
                <SingleImagePanel imgSource={ummi5}/>
                <ThreeImagePanel 
                    imgSourceMiddle={ummi6}
                    imgSourceRight={ummi7}/>
                <SingleImagePanel imgSource={ummi8}/>
                <SingleImagePanel imgSource={ummi9}/>
                <SingleImagePanel imgSource={ummi10}/>
                <SingleImagePanel imgSource={ummi11}/>
                <ThreeImagePanel 
                    imgSourceMiddle={ummi12}
                    imgSourceRight={ummi13}/>
                <SingleImagePanel imgSource={ummi14}/>
                <SingleImagePanel imgSource={ummi15}/>
                <SingleImagePanel imgSource={ummi16}/>
                <ThreeImagePanel 
                    imgSourceLeft={ummi17}
                    imgSourceMiddle={ummi18}/>
                <SingleImagePanel imgSource={ummi19}/>
                <SingleImagePanel imgSource={ummi20}/>
                <SingleImagePanel imgSource={ummi21}/>
                <ThreeImagePanel 
                    imgSourceMiddle={ummi22}
                    imgSourceRight={ummi23}
                    additionalStyle="lastPanelMarginBottom"/>

                <ProjectTextPanel
                    projectTitle="titleUmmi"
                    projectText="bodyUmmi"
                    projectItems="pageItemsUmmi"
                    projectFotoText="none"
                    navLeftLabel="titleCapitolio"
                    navRightLabel="titleEssenceCol"
                    navigateTo={this.props.navigateTo}
                    leftNavUrl={Utils.grafemaUrls.capitolioProject}
                    rightNavUrl={Utils.grafemaUrls.essenceColProject}
                />
            </div>
        );
    }
}

export default UmmiProj;