import React from 'react';

import grafema1 from './../../assets/armazem/grafema_armazem-da-alfandega_01.jpg'
import grafema2 from './../../assets/armazem/grafema_armazem-da-alfandega_02.jpg'
import grafema3 from './../../assets/armazem/grafema_armazem-da-alfandega_03.jpg'
import grafema4 from './../../assets/armazem/grafema_armazem-da-alfandega_04.jpg'
import grafema5 from './../../assets/armazem/grafema_armazem-da-alfandega_05.jpg'
import grafema6 from './../../assets/armazem/grafema_armazem-da-alfandega_06.jpg'
import grafema7 from './../../assets/armazem/grafema_armazem-da-alfandega_07.gif'
import grafema8 from './../../assets/armazem/grafema_armazem-da-alfandega_08.jpg'
import grafema9 from './../../assets/armazem/grafema_armazem-da-alfandega_09.jpg'
import grafema10 from './../../assets/armazem/grafema_armazem-da-alfandega_10.jpg'
import grafema11 from './../../assets/armazem/grafema_armazem-da-alfandega_11.jpg'
import grafema12 from './../../assets/armazem/grafema_armazem-da-alfandega_12.jpg'
import grafema13 from './../../assets/armazem/grafema_armazem-da-alfandega_13.jpg'
import grafema14 from './../../assets/armazem/grafema_armazem-da-alfandega_14.jpg'
import grafema15 from './../../assets/armazem/grafema_armazem-da-alfandega_15.jpg'
import grafema16 from './../../assets/armazem/grafema_armazem-da-alfandega_16.mp4'
import grafema17 from './../../assets/armazem/grafema_armazem-da-alfandega_17.jpg'

import {SingleImagePanel, ThreeImagePanel, ProjectTextPanel} from './../componentUtils/projectImgPanel.js'
import * as Utils from './../../utils/utils.js';

class ArmazemProj extends React.Component {

    render() {
    
        return (
            <div>
                <SingleImagePanel imgSource={grafema1}/>
                <ThreeImagePanel 
                    imgSourceLeft={grafema2}
                    imgSourceRight={grafema3}/>
                <SingleImagePanel imgSource={grafema4}/>
                <ThreeImagePanel 
                    imgSourceMiddle={grafema5}
                    imgSourceRight={grafema6}/>
                <SingleImagePanel imgSource={grafema7}/>
                <ThreeImagePanel 
                    imgSourceLeft={grafema8}
                    imgSourceMiddle={grafema9}
                />
                <SingleImagePanel imgSource={grafema10}/>
                <ThreeImagePanel 
                    imgSourceMiddle={grafema11}
                    imgSourceRight={grafema12}
                />
                <SingleImagePanel imgSource={grafema13}/>
                <ThreeImagePanel 
                    imgSourceLeft={grafema14}
                    imgSourceMiddle={grafema15}
                    
                />
                <SingleImagePanel imgSource={grafema16}/>
                <SingleImagePanel 
                    imgSource={grafema17}
                    additionalStyle="lastPanelMarginBottom"
                />
                <ProjectTextPanel
                    projectTitle="titleArmazem"
                    projectText="bodyArmazem"
                    projectFotoText="none"
                    projectItems="pageItemsArmazem"
                    navLeftLabel="titleSevere"
                    navRightLabel="titleGim"
                    navigateTo={this.props.navigateTo}
                    leftNavUrl={Utils.grafemaUrls.severeProject}
                    rightNavUrl={Utils.grafemaUrls.gimTonicoProject}
                />
            </div>
        );
    }
}

export default ArmazemProj;