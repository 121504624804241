import React from 'react';

import casamentoSS1 from './../../assets/casamentoSS/grafema_ss-wedding-identity_01.jpg'
import casamentoSS2 from './../../assets/casamentoSS/grafema_ss-wedding-identity_02.jpg'
import casamentoSS3 from './../../assets/casamentoSS/grafema_ss-wedding-identity_03.jpg'
import casamentoSS4 from './../../assets/casamentoSS/grafema_ss-wedding-identity_04.jpg'
import casamentoSS5 from './../../assets/casamentoSS/grafema_ss-wedding-identity_05.jpg'
import casamentoSS6 from './../../assets/casamentoSS/grafema_ss-wedding-identity_06.mp4'
import casamentoSS7 from './../../assets/casamentoSS/grafema_ss-wedding-identity_07.jpg'
import casamentoSS8 from './../../assets/casamentoSS/grafema_ss-wedding-identity_08.jpg'
import casamentoSS9 from './../../assets/casamentoSS/grafema_ss-wedding-identity_09.jpg'
import casamentoSS10 from './../../assets/casamentoSS/grafema_ss-wedding-identity_10.jpg'
import casamentoSS11 from './../../assets/casamentoSS/grafema_ss-wedding-identity_11.jpg'
import casamentoSS12 from './../../assets/casamentoSS/grafema_ss-wedding-identity_12.jpg'

import {SingleImagePanel, ThreeImagePanel, ProjectTextPanel} from './../componentUtils/projectImgPanel.js'
import * as Utils from './../../utils/utils.js';

class CasamentoSSProj extends React.Component {

    render() {
    
        return (
            <div>
                <SingleImagePanel imgSource={casamentoSS1}/>
                <SingleImagePanel imgSource={casamentoSS2}/>
                <ThreeImagePanel 
                    imgSourceLeft={casamentoSS3}
                    imgSourceRight={casamentoSS4}/>
                <SingleImagePanel imgSource={casamentoSS5}/>
                <SingleImagePanel imgSource={casamentoSS6}/>
                <ThreeImagePanel 
                    imgSourceMiddle={casamentoSS7}
                    imgSourceRight={casamentoSS8}/>
                <SingleImagePanel imgSource={casamentoSS9}/>
                <SingleImagePanel imgSource={casamentoSS10}/>
                <ThreeImagePanel 
                    imgSourceLeft={casamentoSS11}
                    imgSourceMiddle={casamentoSS12}
                    additionalStyle="lastPanelMarginBottom"/>
                <ProjectTextPanel
                    projectTitle="titleCasamentoSS"
                    projectText="bodyCasamentoSS"
                    projectItems="pageItemsCasamentoSS"
                    projectFotoText="fotoCasamentoSS"
                    fotoUrl="https://www.rsmphoto.pt"
                    fotoGrafema={true}
                    navLeftLabel="titleMaisMenos"
                    navRightLabel="titleCaleOiro"
                    navigateTo={this.props.navigateTo}
                    leftNavUrl={Utils.grafemaUrls.maisMenosProject}
                    rightNavUrl={Utils.grafemaUrls.caleOiroPackageProject}
                />
            </div>
        );
    }
}

export default CasamentoSSProj;