import React from 'react';

import clivitalbio1 from './../../assets/clivitalbio/grafema_clivitalbio_01.jpg'
import clivitalbio2 from './../../assets/clivitalbio/grafema_clivitalbio_02.jpg'
import clivitalbio3 from './../../assets/clivitalbio/grafema_clivitalbio_03.jpg'
import clivitalbio4 from './../../assets/clivitalbio/grafema_clivitalbio_04.jpg'
import clivitalbio5 from './../../assets/clivitalbio/grafema_clivitalbio_05.jpg'
import clivitalbio6 from './../../assets/clivitalbio/grafema_clivitalbio_06.jpg'
import clivitalbio7 from './../../assets/clivitalbio/grafema_clivitalbio_07.jpg'
import clivitalbio8 from './../../assets/clivitalbio/grafema_clivitalbio_08.mp4'
import clivitalbio9 from './../../assets/clivitalbio/grafema_clivitalbio_09.jpg'
import clivitalbio10 from './../../assets/clivitalbio/grafema_clivitalbio_10.jpg'
import clivitalbio11 from './../../assets/clivitalbio/grafema_clivitalbio_11.jpg'
import clivitalbio12 from './../../assets/clivitalbio/grafema_clivitalbio_12.mp4'
import clivitalbio13 from './../../assets/clivitalbio/grafema_clivitalbio_13.jpg'
import clivitalbio14 from './../../assets/clivitalbio/grafema_clivitalbio_14.jpg'
import clivitalbio15 from './../../assets/clivitalbio/grafema_clivitalbio_15.jpg'
import clivitalbio16 from './../../assets/clivitalbio/grafema_clivitalbio_16.jpg'
import clivitalbio17 from './../../assets/clivitalbio/grafema_clivitalbio_17.jpg'
import clivitalbio18 from './../../assets/clivitalbio/grafema_clivitalbio_18.jpg'
import clivitalbio19 from './../../assets/clivitalbio/grafema_clivitalbio_19.jpg'


import {SingleImagePanel, ThreeImagePanel, ProjectTextPanel} from './../componentUtils/projectImgPanel.js'
import * as Utils from './../../utils/utils.js';

class ClivitalbioProj extends React.Component {

    render() {
    
        return (
            <div>
                <SingleImagePanel imgSource={clivitalbio1}/>
                <ThreeImagePanel 
                    imgSourceLeft={clivitalbio2}
                    imgSourceMiddle={clivitalbio3}/>
                <SingleImagePanel imgSource={clivitalbio4}/>
                <SingleImagePanel imgSource={clivitalbio5}/>
                <ThreeImagePanel 
                    imgSourceLeft={clivitalbio6}
                    imgSourceRight={clivitalbio7}/>
                <SingleImagePanel imgSource={clivitalbio8}/>
                <SingleImagePanel imgSource={clivitalbio9}/>
                <ThreeImagePanel 
                    imgSourceLeft={clivitalbio10}
                    imgSourceRight={clivitalbio11}/>
                <SingleImagePanel imgSource={clivitalbio12}/>
                <SingleImagePanel imgSource={clivitalbio13}/>
                <ThreeImagePanel 
                    imgSourceMiddle={clivitalbio14}
                    imgSourceRight={clivitalbio15}/>
                <SingleImagePanel imgSource={clivitalbio16}/>
                <ThreeImagePanel 
                    imgSourceLeft={clivitalbio17}
                    imgSourceRight={clivitalbio18}/>
                <SingleImagePanel imgSource={clivitalbio19}
                    additionalStyle="lastPanelMarginBottom"/>

                <ProjectTextPanel
                    projectTitle="titleclivitalbio"
                    projectText="bodyClivitalbio"
                    projectItems="pageItemsClivitalbio"
                    projectFotoText="none"
                    navLeftLabel="titleAmcc"
                    navRightLabel="titleSecondLiferesin"
                    navigateTo={this.props.navigateTo}
                    leftNavUrl={Utils.grafemaUrls.amccProject}
                    rightNavUrl={Utils.grafemaUrls.secondliferesinProject}
                />
            </div>
        );
    }
}

export default ClivitalbioProj;