import React from 'react';

import book1 from './../../assets/recta-intencao-book/grafema_recta-intencao-book_01.jpg'
import book2 from './../../assets/recta-intencao-book/grafema_recta-intencao-book_02.jpg'
import book3 from './../../assets/recta-intencao-book/grafema_recta-intencao-book_03.jpg'
import book4 from './../../assets/recta-intencao-book/grafema_recta-intencao-book_04.jpg'
import book5 from './../../assets/recta-intencao-book/grafema_recta-intencao-book_05.jpg'
import book6 from './../../assets/recta-intencao-book/grafema_recta-intencao-book_06.jpg'
import book7 from './../../assets/recta-intencao-book/grafema_recta-intencao-book_07.jpg'
import book8 from './../../assets/recta-intencao-book/grafema_recta-intencao-book_08.jpg'
import book9 from './../../assets/recta-intencao-book/grafema_recta-intencao-book_09.jpg'
import book10 from './../../assets/recta-intencao-book/grafema_recta-intencao-book_10.jpg'
import book11 from './../../assets/recta-intencao-book/grafema_recta-intencao-book_11.jpg'
import book12 from './../../assets/recta-intencao-book/grafema_recta-intencao-book_12.jpg'
import book13 from './../../assets/recta-intencao-book/grafema_recta-intencao-book_13.jpg'
import book14 from './../../assets/recta-intencao-book/grafema_recta-intencao-book_14.jpg'


import {SingleImagePanel, ThreeImagePanel, ProjectTextPanel} from './../componentUtils/projectImgPanel.js'
import * as Utils from './../../utils/utils.js';

class RectaIntencaoBookProj extends React.Component {

    render() {
    
        return (
            <div>
                <ThreeImagePanel 
                    imgSourceMiddle={book1}
                    imgSourceRight={book2}/>
                <SingleImagePanel imgSource={book3}/>
                <SingleImagePanel imgSource={book4}/>
                <ThreeImagePanel 
                    imgSourceLeft={book5}
                    imgSourceRight={book6}/>
                <SingleImagePanel imgSource={book7}/>
                <SingleImagePanel imgSource={book8}/>
                <ThreeImagePanel 
                    imgSourceLeft={book9}
                    imgSourceMiddle={book10}/>
                <SingleImagePanel imgSource={book11}/>
                <ThreeImagePanel 
                    imgSourceMiddle={book12}
                    imgSourceRight={book13}/>
                <SingleImagePanel imgSource={book14}
                    additionalStyle="lastPanelMarginBottom"/>               
                    
                <ProjectTextPanel
                    projectTitle="titleRectaIntencaoBook"
                    projectText="bodyRectaIntencao"
                    projectFotoText="none"
                    projectItems="pageItemsVirus"
                    navLeftLabel="titleCoimbra"
                    navRightLabel="titleSevere"
                    navigateTo={this.props.navigateTo}
                    leftNavUrl={Utils.grafemaUrls.coimbraProject}
                    rightNavUrl={Utils.grafemaUrls.severeProject}
                />
            </div>
        );
    }
}

export default RectaIntencaoBookProj;