export const computeOpacityHelperLanding = (boundingRect) => {

    let opacity = 1.0;
    if(boundingRect.top < 0) {

        if(boundingRect.top + boundingRect.height < 0) {
            // console.log("Opacity is 0");
            opacity = 0.0;
        }
        else {
            let dimmedOpacity = (boundingRect.top + boundingRect.height) / boundingRect.height;
            //console.log("Opacity is " + dimmedOpacity);
            //opacity = dimmedOpacity; increasing the opacity factor
            opacity = dimmedOpacity * 0.5;
        }
    }
    else {
        
        // console.log("Opacity is 1");
        opacity = 1.0;
    }
    
    return opacity;
};

export const computeOpacityHelperPortrait = (boundingRect) => {

    let opacity = 1.0;
    if(boundingRect.top < 0) {

        if(boundingRect.top + boundingRect.height < 0) {
            // console.log("Opacity is 0");
            opacity = 0.0;
        }
        else {
            let dimmedOpacity = (boundingRect.top + boundingRect.height) / boundingRect.height;
            //console.log("Opacity is " + dimmedOpacity);
            //opacity = dimmedOpacity; increasing the opacity factor
            opacity = dimmedOpacity;
        }
    }
    else {
        
        // console.log("Opacity is 1");
        opacity = 1.0;
    }
    
    return opacity;
};

export const grafemaUrls = {

    landingPage: "/",
    studioPage: "/studio",
    pPolicyPage: "/privacy-policy",
    armazemProject: "/armazemdaalfandega",
    gimTonicoProject: "/gimtonico",
    virusProject: "/thecreativityvirus",
    minhaCasaProject: "/minhacasa",
    pciProject: "/f1rst",
    caleProject: "/tourguidecaledooiro",
    campanhaUAProject: "/illustrationsuacampaign",
    pilatesProject: "/pilates",
    martiniProject: "/martinicontest",
    designFactoryProject: "/designfactorykitchenpictograms",
    nutranaProject: "/nutrana",
    maisMenosProject: "/+-identity",
    casamentoSSProject: "/ssweddingidentity",
    caleOiroPackageProject: "/packagingcaledooiro",
    rectaIntencaoBookProject: "/rectaintencaobook",
    coimbraProject: "/coimbra2027",
    anguraProject: "/angura",
    secondliferesinProject: "/2ndliferesin",
    severeProject: "/severewebsite",
    essenceColProject: "/theessencecollective",
    cicecoProject: "/ciceco",
    amccProject: "/amcc",
    clivitalbioProject: "/clivitalbio",
    capitolioProject: "/capitolio",
    setProject: "/set",
    ummitProject: "/ummi"
}