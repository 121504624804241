import React from 'react';

import severe1 from './../../assets/severe-website/moldura-3-NOVO-site.mp4'
import severe2 from './../../assets/severe-website/grafema_severe_02.jpg'
import severe3 from './../../assets/severe-website/grafema_severe_03.jpg'
import severe4 from './../../assets/severe-website/moldura-2-NOVO-site.mp4'
import severe5 from './../../assets/severe-website/grafema_severe_05.jpg'
import severe6 from './../../assets/severe-website/grafema_severe_06.jpg'
import severe7 from './../../assets/severe-website/moldura-3-NOVO-site.mp4'
import severe8 from './../../assets/severe-website/grafema_severe_08.jpg'


import {SingleImagePanel, ThreeImagePanel, ProjectTextPanel} from './../componentUtils/projectImgPanel.js'
import * as Utils from './../../utils/utils.js';

class SevereProj extends React.Component {

    render() {
    
        return (
            <div>
                <SingleImagePanel imgSource={severe1}/>
                <ThreeImagePanel 
                    imgSourceLeft={severe2}
                    imgSourceRight={severe3}/>
                <SingleImagePanel imgSource={severe4}/>
                <ThreeImagePanel 
                    imgSourceLeft={severe5}
                    imgSourceMiddle={severe6}/>
                <SingleImagePanel imgSource={severe7}/>
                <SingleImagePanel imgSource={severe8}
                    additionalStyle="lastPanelMarginBottom"/>               
                    
                <ProjectTextPanel
                    projectTitle="titleSevere"
                    projectText="bodySevere"
                    projectFotoText="none"
                    projectItems="pageItemsSevere"
                    navLeftLabel="titleRectaIntencaoBook"
                    navRightLabel="titleArmazem"
                    navigateTo={this.props.navigateTo}
                    leftNavUrl={Utils.grafemaUrls.rectaIntencaoBookProject}
                    rightNavUrl={Utils.grafemaUrls.armazemProject}
                />
            </div>
        );
    }
}

export default SevereProj;