import React from 'react';

import maisMenos1 from './../../assets/maisMenos/grafema_+-identity_01.jpg'
import maisMenos2 from './../../assets/maisMenos/grafema_+-identity_02.gif'
import maisMenos3 from './../../assets/maisMenos/grafema_+-identity_03.jpg'
import maisMenos4 from './../../assets/maisMenos/grafema_+-identity_04.jpg'
import maisMenos5 from './../../assets/maisMenos/grafema_+-identity_05.jpg'
import maisMenos6 from './../../assets/maisMenos/grafema_+-identity_06.jpg'
import maisMenos7 from './../../assets/maisMenos/grafema_+-identity_07.jpg'


import {SingleImagePanel, ThreeImagePanel, ProjectTextPanel} from './../componentUtils/projectImgPanel.js'
import * as Utils from './../../utils/utils.js';

class MaisMenosProj extends React.Component {

    render() {
    
        return (
            <div>
                <SingleImagePanel imgSource={maisMenos1}/>
                <ThreeImagePanel 
                    imgSourceMiddle={maisMenos2}
                    imgSourceRight={maisMenos3}/>
                <SingleImagePanel imgSource={maisMenos4}/>
                <ThreeImagePanel 
                    imgSourceLeft={maisMenos5}
                    imgSourceMiddle={maisMenos6}/>
                <SingleImagePanel imgSource={maisMenos7}
                    additionalStyle="lastPanelMarginBottom"/>
                <ProjectTextPanel
                    projectTitle="titleMaisMenos"
                    projectText="bodyMaisMenos"
                    projectItems="pageItemsMaisMenos"
                    projectFotoText="none"
                    navLeftLabel="titleNutrana"
                    navRightLabel="titleCasamentoSS"
                    navigateTo={this.props.navigateTo}
                    leftNavUrl={Utils.grafemaUrls.nutranaProject}
                    rightNavUrl={Utils.grafemaUrls.casamentoSSProject}
                />
            </div>
        );
    }
}

export default MaisMenosProj;