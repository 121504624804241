import React from "react";
import { IntlProvider, addLocaleData } from "react-intl";

import enDefault from "react-intl/locale-data/en";
import ptDefault from "react-intl/locale-data/pt";
import pt from '../locales/pt.json'
import en from '../locales/en.json'

addLocaleData([...enDefault, ...ptDefault]);

const Context = React.createContext();

class LocaleProviderWrapper extends React.Component {
  constructor(...args) {
    super(...args);

    var myStorage = window.localStorage;

    this.switchToEnglish = () =>
      this.setState({ locale: "en", messages: en });

    this.switchToPortuguese = () =>
      this.setState({ locale: "pt", messages: pt });

    this.state = {
      locale: (myStorage.getItem("locale") == "en")? "en": "pt",
      messages: (myStorage.getItem("locale") == "en")? en: pt,
      switchToEnglish: this.switchToEnglish,
      switchToPortuguese: this.switchToPortuguese
    };
  }

  render() {
    const { children } = this.props;
    const { locale, messages } = this.state;
    return (
      <Context.Provider value={this.state}>
        <IntlProvider
          key={locale}
          locale={locale}
          messages={messages}
        >
          {children}
        </IntlProvider>
      </Context.Provider>
    );
  }
}

export { LocaleProviderWrapper, Context as IntlContext };
