import React from 'react';

import gim1 from './../../assets/gimTonico/grafema_gim-tonico_01.jpg'
import gim2 from './../../assets/gimTonico/grafema_gim-tonico_02.mp4'
import gim3 from './../../assets/gimTonico/grafema_gim-tonico_03.jpg'
import gim4 from './../../assets/gimTonico/grafema_gim-tonico_04.jpg'
import gim5 from './../../assets/gimTonico/grafema_gim-tonico_05.jpg'
import gim6 from './../../assets/gimTonico/grafema_gim-tonico_06.jpg'
import gim7 from './../../assets/gimTonico/grafema_gim-tonico_07.jpg'
import gim8 from './../../assets/gimTonico/grafema_gim-tonico_08.mp4'
import gim9 from './../../assets/gimTonico/grafema_gim-tonico_09.jpg'
import gim10 from './../../assets/gimTonico/grafema_gim-tonico_10.jpg'
import gim11 from './../../assets/gimTonico/grafema_gim-tonico_11.jpg'
import gim12 from './../../assets/gimTonico/grafema_gim-tonico_12.jpg'
import gim13 from './../../assets/gimTonico/grafema_gim-tonico_13.mp4'
import gim14 from './../../assets/gimTonico/grafema_gim-tonico_14.jpg'
import gim15 from './../../assets/gimTonico/grafema_gim-tonico_15.jpg'

import {SingleImagePanel, ThreeImagePanel, ProjectTextPanel} from './../componentUtils/projectImgPanel.js'
import * as Utils from './../../utils/utils.js';

class GimProj extends React.Component {

    render() {
    
        return (
            <div>
                <SingleImagePanel imgSource={gim1}/>
                <SingleImagePanel imgSource={gim2}/>
                <ThreeImagePanel 
                    imgSourceLeft={gim3}
                    imgSourceMiddle={gim4}/>
                <SingleImagePanel imgSource={gim5}/>
                <ThreeImagePanel 
                    imgSourceMiddle={gim6}
                    imgSourceRight={gim7}/>
                <SingleImagePanel imgSource={gim8}/>
                <SingleImagePanel imgSource={gim9}/>
                <ThreeImagePanel 
                    imgSourceLeft={gim10}
                    imgSourceMiddle={gim11}/>
                <SingleImagePanel imgSource={gim12}/>
                <SingleImagePanel imgSource={gim13}/>
                <ThreeImagePanel 
                    imgSourceLeft={gim14}
                    imgSourceMiddle={gim15}
                    additionalStyle="lastPanelMarginBottom"/>
                <ProjectTextPanel
                    projectTitle="titleGim"
                    projectText="bodyGim"
                    projectFotoText="fotoTextGim"
                    fotoUrl="https://www.instagram.com/mariliamm.fotografia/"
                    awardText="awardGim"
                    projectItems="pageItemsGim"
                    navLeftLabel="titleArmazem"
                    navRightLabel="titleVirus"
                    navigateTo={this.props.navigateTo}
                    leftNavUrl={Utils.grafemaUrls.armazemProject}
                    rightNavUrl={Utils.grafemaUrls.virusProject}
                />
            </div>
        );
    }
}

export default GimProj;