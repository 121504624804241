import React from 'react';

import './../../style/desktopProject.css';
import './../../style/mobileProject.css';

import leftArrow from './../../assets/left.svg'
import rightArrow from './../../assets/right.svg'

import * as Utils from './../../utils/utils.js'
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";

class SingleImagePanel extends React.Component {

    constructor(props) {
        super(props);
        this.myRef = React.createRef();
    
        this.windowScrollEvent = this.windowScrollEvent.bind(this);
    }
    
    componentDidMount() {
        
        if (typeof window !== "undefined") {
          
          window.addEventListener("scroll", this.windowScrollEvent);
        }
    }
    
    componentWillUnmount() {
    
        window.removeEventListener('scroll', this.windowScrollEvent);
    }
    
    windowScrollEvent() {
        
        this.myRef.current.style.opacity = 
          Utils.computeOpacityHelperPortrait(this.myRef.current.getBoundingClientRect());
    }

    renderElement(internalSource) {

        if(internalSource.includes("mp4"))
          return this.renderVideo(internalSource)
        else
          return this.renderImage(internalSource);
    }
    
    renderVideo(internalSource) {
        
        return (
            <video src={internalSource} className="singleImage" playsInline autoPlay muted loop/>
        );
    }
    
    renderImage(internalSource) {
    
        return (
            <img src={internalSource} className="singleImage"/>
        );
    }

    render() {
    
        return (
            <div ref={this.myRef} className={"singleImageContainer " + this.props.additionalStyle}>
               {this.renderElement(this.props.imgSource)} 
            </div>
        );
    }
}

class ThreeImagePanel extends React.Component {

    constructor(props) {
        super(props);
        this.myRef = React.createRef();
    
        this.windowScrollEvent = this.windowScrollEvent.bind(this);
        var finalLeftStyle = (props.imgSourceMiddle)? "threeImgLeftAbs": "threeImgLeftNormal";
        var finalMiddleStyle = (props.imgSourceRight)? "threeImgMiddle": "threeImgMiddle threeImgRightMiddleMobileCheat";
        
        this.state = {
            finalLeftStyle : finalLeftStyle,
            finalMiddleStyle: finalMiddleStyle
          }
    }
    
    componentDidMount() {
        
        if (typeof window !== "undefined") {
          
          window.addEventListener("scroll", this.windowScrollEvent);
        }
    }
    
    componentWillUnmount() {
    
        window.removeEventListener('scroll', this.windowScrollEvent);
    }
    
    windowScrollEvent() {
        
        this.myRef.current.style.opacity = 
          Utils.computeOpacityHelperPortrait(this.myRef.current.getBoundingClientRect());
    }

    renderElement(internalSource, imgStyle) {

        if(!internalSource)
            return;

        if(internalSource.includes("mp4"))
          return this.renderVideo(internalSource, imgStyle)
        else
          return this.renderImage(internalSource, imgStyle);
    }
    
    renderVideo(internalSource, imgStyle) {
        
        return (
            <video src={internalSource} className={imgStyle} playsInline autoPlay muted loop/>
        );
    }
    
    renderImage(internalSource, imgStyle) {
    
        return (
            <img src={internalSource} className={imgStyle}/>
        );
    }

    render() {
    
        return (
            <div className={"threeImgContainer " + this.props.additionalStyle} ref={this.myRef}>
               {this.renderElement(this.props.imgSourceLeft, this.state.finalLeftStyle)}
               {this.renderElement(this.props.imgSourceMiddle, this.state.finalMiddleStyle)}
               {this.renderElement(this.props.imgSourceRight, "threeImgRight")}
            </div>
        );
    }
}

class ProjectTextPanel extends React.Component {

    constructor(props) {
        super(props);
         
        var fotoStyle = (this.props.projectFotoText !== "none")? "projectFotoText" : "doNotDisplay";

        this.state = {
            fotoStyle: fotoStyle
        }
    }

    renderFotoPanel() {

        if(this.props.fotoUrl) {
        
            if(!this.props.fotoGrafema) {

                return (
                    <div className={this.state.fotoStyle}>
                        <FormattedMessage id="fotoTitle"/>
                        <span onClick={e => window.open(this.props.fotoUrl)} className="fotoLinkStyle"><FormattedHTMLMessage id={this.props.projectFotoText}/></span>
                    </div>
                );
            }
            else {

                return (
                    <div className={this.state.fotoStyle}>
                        <FormattedMessage id="fotoTitle"/>
                        <span onClick={e => window.open(this.props.fotoUrl)} className="fotoLinkStyle"><FormattedMessage id={this.props.projectFotoText}/></span>
                        <FormattedMessage id="fotoAndGrafema"/>
                    </div>
                );
            }
        }
        else {

            if(!this.props.fotoGrafema) {

                return (
                    <div className={this.state.fotoStyle}>
                        <FormattedMessage id="fotoTitle"/>
                        <FormattedHTMLMessage id={this.props.projectFotoText}/>
                    </div>
                );
            }
            else {

                return (
                    <div className={this.state.fotoStyle}>
                        <FormattedMessage id="fotoTitle"/>
                        <FormattedMessage id={this.props.projectFotoText}/>
                        <FormattedMessage id="fotoAndGrafema"/>
                    </div>
                );
            }
        }
    }

    renderAwardPanel() {

        if(this.props.awardText) {

            return (
                <div className="projectMainText">
                    <FormattedHTMLMessage id={this.props.awardText}/>
                </div>
            );
        }
    }

    render() {
    
        return (
            <div>
                <div className="projectDescContainer">
                    <div className="projectCorpContainer projectTitle">
                        <FormattedMessage id={this.props.projectTitle}/>
                    </div>
                    <div>
                        <div className="projectCorpContainer lastPanelMarginBottom">
                            <div className="projectMainText">
                                <FormattedHTMLMessage id={this.props.projectText}/>
                            </div>
                            {this.renderFotoPanel()}
                            {this.renderAwardPanel()}
                            
                        </div>
                        <div className="projectItemList lastPanelMarginBottom">
                            <FormattedHTMLMessage id={this.props.projectItems}/>
                        </div>
                    </div>
                </div>
                <div className="navBottomProject doubleLastPanelMarginBottom">
                    <div className="navArrowCont navLeftContainer" onClick={() => this.props.navigateTo(this.props.leftNavUrl)}>
                        <img src={leftArrow} className="arrow"/>
                        <div className="arrowText leftArrowText">
                            <FormattedHTMLMessage id={this.props.navLeftLabel}/>
                        </div>
                    </div>
                    <div className="navArrowCont navRightContainer" onClick={() => this.props.navigateTo(this.props.rightNavUrl)}>
                        <img src={rightArrow} className="arrow rightArrow"/>
                        <div className="arrowText rightArrowText">
                            <FormattedHTMLMessage id={this.props.navRightLabel}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export {SingleImagePanel, ThreeImagePanel, ProjectTextPanel};