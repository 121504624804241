import React from 'react';

import './../../style/desktopLanding.css';
import './../../style/mobileLanding.css';

import upArrow from './../../assets/up.svg'

import { FormattedMessage } from "react-intl";


class Footer extends React.Component {

    getInTouchEvent() {
      
      console.log(window.innerWidth);
      
      if(window.innerWidth > 749)
          
        window.open("mailto:hello@grafemadesign.com")
    }

    render() {
  
      return(
        <div style={{float: "left", width: "100%"}}>
          {/* <div className="footerContactContainer footerClickable" onClick={e => window.open("mailto:hello@grafemadesign.com")}>
            <FormattedMessage id="getInTouch"/>
          </div> */}
          <div className="footerBottomContainer">
            <div className="footerBottomContainerLeft footerContactContainer footerClickable" onClick={() => this.getInTouchEvent()}>
              <FormattedMessage id="getInTouch"/>
            </div>
            <div className="footerBottomContainerCenter">
              <div className="footerElement footerClickable" onClick={e => window.open("mailto:hello@grafemadesign.com")}>hello@grafemadesign.com</div>
              <div className="footerElement">
                <FormattedMessage id="nationalCallText"/>
              </div>
              <div className="footerElement" /*onClick={e => window.open('tel:(+351) 919 024 532')}*/>(+351) 919 024 532</div>
              <div className="footerElement" /*onClick={e => window.location.href='tel:(+351) 913 268 656'}*/>(+351) 913 268 656</div>
            </div>
            <div className="footerBottomMainRight">
              <div className="footerBottomContainerRight">
                <div className="footerClickable" onClick={e => window.open("https://www.instagram.com/grafemadesign/")}>Instagram</div>
                <div className="footerElement footerClickable" onClick={e => window.open("https://www.behance.net/grafemadesign")}>Behance</div>
                <div className="footerElement footerClickable" onClick={e => window.open("https://www.linkedin.com/company/grafemadesign")}>LinkedIn</div>
                <div className="footerElement footerClickable" onClick={e => window.open("https://www.facebook.com/grafemadesign")}>Facebook</div>
              </div>
            </div>
            <img src={upArrow} className="footerUpArrow" onClick={e => window.scrollTo(0, 0)} />
          </div>
          <div className="copyrightContainer">
              <div className="copyrightContainerFirst">©&nbsp;grafema&nbsp;</div>
              <div>{new Date().getFullYear()}&nbsp;</div>
              <div><FormattedMessage id="copyrightMessage"/></div>
          </div>
        </div>
      );
    }
  }

  export default Footer;