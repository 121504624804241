import React from 'react';

import minhaCasa1 from './../../assets/minhaCasa/grafema_minha-casa_01.jpg'
import minhaCasa2 from './../../assets/minhaCasa/grafema_minha-casa_02.gif'
import minhaCasa3 from './../../assets/minhaCasa/grafema_minha-casa_03.jpg'
import minhaCasa4 from './../../assets/minhaCasa/grafema_minha-casa_04.jpg'
import minhaCasa5 from './../../assets/minhaCasa/grafema_minha-casa_05.jpg'
import minhaCasa6 from './../../assets/minhaCasa/grafema_minha-casa_06.jpg'
import minhaCasa7 from './../../assets/minhaCasa/grafema_minha-casa_07.mp4'
import minhaCasa8 from './../../assets/minhaCasa/grafema_minha-casa_08.jpg'
import minhaCasa9 from './../../assets/minhaCasa/grafema_minha-casa_09.jpg'
import minhaCasa10 from './../../assets/minhaCasa/grafema_minha-casa_10.jpg'
import minhaCasa11 from './../../assets/minhaCasa/grafema_minha-casa_11.jpg'



import {SingleImagePanel, ThreeImagePanel, ProjectTextPanel} from './../componentUtils/projectImgPanel.js'
import * as Utils from './../../utils/utils.js';

class MinhaCasaProj extends React.Component {

    render() {
    
        return (
            <div>
                <SingleImagePanel imgSource={minhaCasa1}/>
                <ThreeImagePanel 
                    imgSourceLeft={minhaCasa2}
                    imgSourceMiddle={minhaCasa3}/>
                <SingleImagePanel imgSource={minhaCasa4}/>
                <ThreeImagePanel 
                    imgSourceLeft={minhaCasa5}
                    imgSourceRight={minhaCasa6}/>
                <SingleImagePanel imgSource={minhaCasa7}/>
                <ThreeImagePanel 
                    imgSourceLeft={minhaCasa8}
                    imgSourceMiddle={minhaCasa9}/>
                <SingleImagePanel imgSource={minhaCasa10}
                    additionalStyle="lastPanelMarginBottom"/>
                <ProjectTextPanel
                    projectTitle="titleMinhaCasa"
                    projectText="bodyMinhaCasa"
                    projectFotoText="fotoTextMinhaCasa"
                    projectItems="pageItemsminhaCasa"
                    navLeftLabel="titleVirus"
                    navRightLabel="titlePCI"
                    navigateTo={this.props.navigateTo}
                    leftNavUrl={Utils.grafemaUrls.virusProject}
                    rightNavUrl={Utils.grafemaUrls.pciProject}
                />
            </div>
        );
    }
}

export default MinhaCasaProj;