import React, {Component} from 'react';
import {Switch, Route, withRouter} from 'react-router-dom';
import IdleTimer from 'react-idle-timer';

import { LocaleProviderWrapper } from './utils/LocaleProviderWrapper.js'
import Overlay from './components/componentUtils/overlay.js'
import LandingPage from './components/landing.js';
import StudioPage from './components/studio.js';
import ArmazemProj from './components/projects/armazemProj.js'
import GimProj from './components/projects/gimTonicoProj.js'
import VirusProj from './components/projects/virusProj.js'
import MinhaCasaProj from './components/projects/minhaCasaProj.js'
import PciProj from './components/projects/pciProj.js'
import UAProj from './components/projects/uaCampaignProj.js'
import TourOiroProj from './components/projects/tourCaleOiroProj.js'
import PilatesProj from './components/projects/pilatesProj.js'
import MartiniProj from './components/projects/martiniProj.js'
import DesignFactoryProj from './components/projects/designFactoryProj.js'
import NutranaProj from './components/projects/nutranaProj.js'
import MaisMenosProj from './components/projects/maisMenosProj.js'
import CasamentoSSProj from './components/projects/casamentoSSProj.js'
import PackagingCaleOiroProj from './components/projects/packagingCaleOiro.js'
import RectaIntencaoBookProj from './components/projects/rectaIntencaoBook.js'
import CoimbraProj from './components/projects/coimbraProj.js'
import AnguraProj from './components/projects/anguraProj.js'
import SecondLiferesinProj from './components/projects/secondLiferesinProj.js'
import SevereProj from './components/projects/severeProj.js'
import EssenceProj from './components/projects/essenceProj.js'
import CicecoProj from './components/projects/cicecoProj.js'
import AmccProj from './components/projects/amccProj.js';
import ClivitalbioProj from './components/projects/clivitalbioProj.js';
import CapitolioProj from './components/projects/capitolioProj.js';
import SetProj from './components/projects/setProj.js';
import UmmiProj from './components/projects/ummiProj.js';
import {Header, StickyHeader} from './components/componentUtils/header.js'
import Footer from './components/componentUtils/footer.js'
import GDPRBanner from './components/componentUtils/gdprBanner.js'
import PPolicyPage from './components/ppolicy.js'
import * as Utils from './utils/utils.js'

const idleTime = 1000 * 60 * 2;
// const idleTime = 1000;

class App extends Component {

    constructor(props) {
        super(props);
        this.idleTimer = null;
        this.handleOnAction = this.handleOnAction.bind(this);
        this.handleOnActive = this.handleOnActive.bind(this);
        this.handleOnIdle = this.handleOnIdle.bind(this);
        this.switchLanguageClick = this.switchLanguageClick.bind(this);
        this.navigateTo = this.navigateTo.bind(this);
    
        this.overlayRef = React.createRef();
        this.localeWrapper = React.createRef();
        
        var myStorage = window.localStorage;
        if(!myStorage.getItem('locale'))
            myStorage.setItem('locale', 'en');
        var pathName = window.location.pathname;
        this.state = {
          enStyle: (myStorage.getItem('locale') === "en")? "enNavActive" : "enNav",
          ptStyle: (myStorage.getItem('locale') === "pt")? "ptNavActive" : "ptNav",
          projectStyle: (pathName === Utils.grafemaUrls.studioPage)? "projectsNav": "projectsNavActive",
          studioStyle: (pathName === Utils.grafemaUrls.studioPage)? "studioNavActive": "studioNav"
        }

        // console.log(myStorage.getItem('locale'));
        // console.log(window.location.pathname);
      }
    
    
    
      handleOnAction (event) {
        // console.log('user did something', event)
      }
     
      handleOnActive (event) {
        // console.log('user is active', event)
        // console.log('time remaining', this.idleTimer.getRemainingTime())
      }
      
      handleOnIdle (event) {
        // console.log('user is idle', event)
        // console.log('last active', this.idleTimer.getLastActiveTime())
        // this.localeWrapper.current.switchToDeutsch();
        this.overlayRef.current.triggerOverlay();
      }
    
      switchLanguageClick(lang) {

        console.log("Language switch handler from " + localStorage.getItem('locale') + " to " + lang);
        
        if(localStorage.getItem('locale')!== lang) {
    
          switch(lang) {
    
            case 'pt':
                this.state.curLang = 'pt';
                localStorage.setItem('locale', 'pt');
                this.state.ptStyle = "ptNavActive";
                this.state.enStyle = "enNav";
                this.localeWrapper.current.switchToPortuguese();
                this.forceUpdate();
                break;
            case 'en':
                this.state.curLang = 'en';
                localStorage.setItem('locale', 'en');
                this.state.ptStyle = "ptNav";
                this.state.enStyle = "enNavActive";
                this.localeWrapper.current.switchToEnglish();
                this.forceUpdate();
                break;
            default:
          }
        }
      }  

      navigateTo(path) {

        // console.log(this.props);
        this.props.history.push(path);
        if(path == Utils.grafemaUrls.studioPage) {
            
            this.state.projectStyle = "projectsNav";
            this.state.studioStyle = "studioNavActive";
        }
        else {

            this.state.projectStyle = "projectsNavActive";
            this.state.studioStyle = "studioNav";
        }
        this.forceUpdate();
        window.scrollTo(0, 0);
      }

      render() {
        
        return (
            <LocaleProviderWrapper ref={this.localeWrapper}>
                <div className="innerBody">
                    <IdleTimer
                        ref={ref => { this.idleTimer = ref }}
                        timeout={idleTime}
                        onActive={this.handleOnActive}
                        onIdle={this.handleOnIdle}
                        onAction={this.handleOnAction}
                        debounce={250}/>
                    <Overlay ref={this.overlayRef}/>
                    <StickyHeader 
                      switchLanguageClick={this.switchLanguageClick}
                      navigateTo= {this.navigateTo}
                      projectStyle= {this.state.projectStyle}
                      studioStyle= {this.state.studioStyle}
                      enStyle= {this.state.enStyle}
                      ptStyle= {this.state.ptStyle}
                      projectStyle= {this.state.projectStyle}
                      studioStyle= {this.state.studioStyle}
                    />
                    <Header
                      switchLanguageClick={this.switchLanguageClick}
                      navigateTo= {this.navigateTo}
                      projectStyle= {this.state.projectStyle}
                      studioStyle= {this.state.studioStyle}
                      enStyle= {this.state.enStyle}
                      ptStyle= {this.state.ptStyle}
                      projectStyle= {this.state.projectStyle}
                      studioStyle= {this.state.studioStyle}
                    />
                    <Switch>
                        <Route 
                            exact path={Utils.grafemaUrls.armazemProject}
                            render={props => <ArmazemProj { 
                                ...{
                                    props: this.props,
                                    switchLanguageClick: this.switchLanguageClick,
                                    navigateTo: this.navigateTo
                                }
                            }/>}
                        />
                        <Route 
                            exact path={Utils.grafemaUrls.gimTonicoProject}
                            render={props => <GimProj { 
                                ...{
                                    props: this.props,
                                    switchLanguageClick: this.switchLanguageClick,
                                    navigateTo: this.navigateTo
                                }
                            }/>}
                        />
                        <Route 
                            exact path={Utils.grafemaUrls.virusProject}
                            render={props => <VirusProj { 
                                ...{
                                    props: this.props,
                                    switchLanguageClick: this.switchLanguageClick,
                                    navigateTo: this.navigateTo
                                }
                            }/>}
                        />
                        <Route 
                            exact path={Utils.grafemaUrls.minhaCasaProject}
                            render={props => <MinhaCasaProj { 
                                ...{
                                    props: this.props,
                                    switchLanguageClick: this.switchLanguageClick,
                                    navigateTo: this.navigateTo
                                }
                            }/>}
                        />
                        <Route 
                            exact path={Utils.grafemaUrls.pciProject}
                            render={props => <PciProj { 
                                ...{
                                    props: this.props,
                                    switchLanguageClick: this.switchLanguageClick,
                                    navigateTo: this.navigateTo
                                }
                            }/>}
                        />
                        <Route 
                            exact path={Utils.grafemaUrls.caleProject}
                            render={props => <TourOiroProj { 
                                ...{
                                    props: this.props,
                                    switchLanguageClick: this.switchLanguageClick,
                                    navigateTo: this.navigateTo
                                }
                            }/>}
                        />
                        <Route 
                            exact path={Utils.grafemaUrls.campanhaUAProject}
                            render={props => <UAProj { 
                                ...{
                                    props: this.props,
                                    switchLanguageClick: this.switchLanguageClick,
                                    navigateTo: this.navigateTo
                                }
                            }/>}
                        />
                        <Route 
                            exact path={Utils.grafemaUrls.pilatesProject}
                            render={props => <PilatesProj { 
                                ...{
                                    props: this.props,
                                    switchLanguageClick: this.switchLanguageClick,
                                    navigateTo: this.navigateTo
                                }
                            }/>}
                        />
                        <Route 
                            exact path={Utils.grafemaUrls.martiniProject}
                            render={props => <MartiniProj { 
                                ...{
                                    props: this.props,
                                    switchLanguageClick: this.switchLanguageClick,
                                    navigateTo: this.navigateTo
                                }
                            }/>}
                        />
                        <Route 
                            exact path={Utils.grafemaUrls.designFactoryProject}
                            render={props => <DesignFactoryProj { 
                                ...{
                                    props: this.props,
                                    switchLanguageClick: this.switchLanguageClick,
                                    navigateTo: this.navigateTo
                                }
                            }/>}
                        />
                        <Route 
                            exact path={Utils.grafemaUrls.nutranaProject}
                            render={props => <NutranaProj { 
                                ...{
                                    props: this.props,
                                    switchLanguageClick: this.switchLanguageClick,
                                    navigateTo: this.navigateTo
                                }
                            }/>}
                        />
                        <Route 
                            exact path={Utils.grafemaUrls.maisMenosProject}
                            render={props => <MaisMenosProj { 
                                ...{
                                    props: this.props,
                                    switchLanguageClick: this.switchLanguageClick,
                                    navigateTo: this.navigateTo
                                }
                            }/>}
                        />
                        <Route 
                            exact path={Utils.grafemaUrls.casamentoSSProject}
                            render={props => <CasamentoSSProj { 
                                ...{
                                    props: this.props,
                                    switchLanguageClick: this.switchLanguageClick,
                                    navigateTo: this.navigateTo
                                }
                            }/>}
                        />
                        <Route 
                            exact path={Utils.grafemaUrls.caleOiroPackageProject}
                            render={props => <PackagingCaleOiroProj { 
                                ...{
                                    props: this.props,
                                    switchLanguageClick: this.switchLanguageClick,
                                    navigateTo: this.navigateTo
                                }
                            }/>}
                        />
                        <Route 
                            exact path={Utils.grafemaUrls.rectaIntencaoBookProject}
                            render={props => <RectaIntencaoBookProj { 
                                ...{
                                    props: this.props,
                                    switchLanguageClick: this.switchLanguageClick,
                                    navigateTo: this.navigateTo
                                }
                            }/>}
                        />
                        <Route 
                            exact path={Utils.grafemaUrls.coimbraProject}
                            render={props => <CoimbraProj { 
                                ...{
                                    props: this.props,
                                    switchLanguageClick: this.switchLanguageClick,
                                    navigateTo: this.navigateTo
                                }
                            }/>}
                        />
                        <Route 
                            exact path={Utils.grafemaUrls.anguraProject}
                            render={props => <AnguraProj { 
                                ...{
                                    props: this.props,
                                    switchLanguageClick: this.switchLanguageClick,
                                    navigateTo: this.navigateTo
                                }
                            }/>}
                        />
                        <Route 
                            exact path={Utils.grafemaUrls.secondliferesinProject}
                            render={props => <SecondLiferesinProj { 
                                ...{
                                    props: this.props,
                                    switchLanguageClick: this.switchLanguageClick,
                                    navigateTo: this.navigateTo
                                }
                            }/>}
                        />
                        <Route 
                            exact path={Utils.grafemaUrls.severeProject}
                            render={props => <SevereProj { 
                                ...{
                                    props: this.props,
                                    switchLanguageClick: this.switchLanguageClick,
                                    navigateTo: this.navigateTo
                                }
                            }/>}
                        />
                        <Route 
                            exact path={Utils.grafemaUrls.essenceColProject}
                            render={props => <EssenceProj { 
                                ...{
                                    props: this.props,
                                    switchLanguageClick: this.switchLanguageClick,
                                    navigateTo: this.navigateTo
                                }
                            }/>}
                        />
                        <Route 
                            exact path={Utils.grafemaUrls.cicecoProject}
                            render={props => <CicecoProj { 
                                ...{
                                    props: this.props,
                                    switchLanguageClick: this.switchLanguageClick,
                                    navigateTo: this.navigateTo
                                }
                            }/>}
                        />
                        <Route 
                            exact path={Utils.grafemaUrls.amccProject}
                            render={props => <AmccProj { 
                                ...{
                                    props: this.props,
                                    switchLanguageClick: this.switchLanguageClick,
                                    navigateTo: this.navigateTo
                                }
                            }/>}
                        />
                        <Route 
                            exact path={Utils.grafemaUrls.clivitalbioProject}
                            render={props => <ClivitalbioProj { 
                                ...{
                                    props: this.props,
                                    switchLanguageClick: this.switchLanguageClick,
                                    navigateTo: this.navigateTo
                                }
                            }/>}
                        />

                        <Route 
                            exact path={Utils.grafemaUrls.capitolioProject}
                            render={props => <CapitolioProj { 
                                ...{
                                    props: this.props,
                                    switchLanguageClick: this.switchLanguageClick,
                                    navigateTo: this.navigateTo
                                }
                            }/>}
                        />
                        <Route 
                            exact path={Utils.grafemaUrls.setProject}
                            render={props => <SetProj { 
                                ...{
                                    props: this.props,
                                    switchLanguageClick: this.switchLanguageClick,
                                    navigateTo: this.navigateTo
                                }
                            }/>}
                        />
                        <Route 
                            exact path={Utils.grafemaUrls.ummitProject}
                            render={props => <UmmiProj { 
                                ...{
                                    props: this.props,
                                    switchLanguageClick: this.switchLanguageClick,
                                    navigateTo: this.navigateTo
                                }
                            }/>}
                        />

                        <Route 
                            exact path={Utils.grafemaUrls.studioPage}
                            render={props => <StudioPage { 
                                ...{
                                    props: this.props,
                                    switchLanguageClick: this.switchLanguageClick
                                }
                            }/>}
                        />
                        <Route 
                            exact path={Utils.grafemaUrls.pPolicyPage}
                            render={props => <PPolicyPage { 
                                ...{
                                    props: this.props,
                                    switchLanguageClick: this.switchLanguageClick
                                }
                            }/>}
                        />
                        <Route 
                            render={props => <LandingPage { 
                                ...{
                                    props: this.props,
                                    switchLanguageClick: this.switchLanguageClick,
                                    navigateTo: this.navigateTo
                                }
                            }/>}
                        />
                    </Switch>
                    <Footer />
                    <GDPRBanner 
                        navigateTo= {this.navigateTo}
                    />
                </div>
            </LocaleProviderWrapper>
        );
      }
}

export default withRouter(App);