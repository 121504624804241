import React from 'react';

import ua1 from './../../assets/uaCampaign/grafema_illustrations-ua-campaign_01.jpg'
import ua2 from './../../assets/uaCampaign/grafema_illustrations-ua-campaign_02.jpg'
import ua3 from './../../assets/uaCampaign/grafema_illustrations-ua-campaign_03.jpg'
import ua4 from './../../assets/uaCampaign/grafema_illustrations-ua-campaign_04.jpg'
import ua5 from './../../assets/uaCampaign/grafema_illustrations-ua-campaign_05.jpg'
import ua6 from './../../assets/uaCampaign/grafema_illustrations-ua-campaign_06.jpg'
import ua7 from './../../assets/uaCampaign/grafema_illustrations-ua-campaign_07.gif'
import ua8 from './../../assets/uaCampaign/grafema_illustrations-ua-campaign_08.jpg'
import ua9 from './../../assets/uaCampaign/grafema_illustrations-ua-campaign_09.jpg'
import ua10 from './../../assets/uaCampaign/grafema_illustrations-ua-campaign_10.jpg'

import {SingleImagePanel, ThreeImagePanel, ProjectTextPanel} from './../componentUtils/projectImgPanel.js'
import * as Utils from './../../utils/utils.js';

class UAProj extends React.Component {

    render() {
    
        return (
            <div>
                <SingleImagePanel imgSource={ua1}/>
                <ThreeImagePanel 
                    imgSourceMiddle={ua2}
                    imgSourceRight={ua3}/>
                <SingleImagePanel imgSource={ua4}/>
                <SingleImagePanel imgSource={ua5}/>
                <ThreeImagePanel 
                    imgSourceLeft={ua6}
                    imgSourceMiddle={ua7}/>
                <SingleImagePanel imgSource={ua8}/>
                <SingleImagePanel imgSource={ua9}/>
                <SingleImagePanel imgSource={ua10}
                    additionalStyle="lastPanelMarginBottom"/>

                <ProjectTextPanel
                    projectTitle="titleCampanhaUA"
                    projectText="bodyCampanhaUA"
                    projectItems="pageItemsUA"
                    projectFotoText="none"
                    navLeftLabel="titleCale"
                    navRightLabel="titlePilates"
                    navigateTo={this.props.navigateTo}
                    leftNavUrl={Utils.grafemaUrls.caleProject}
                    rightNavUrl={Utils.grafemaUrls.pilatesProject}
                />
            </div>
        );
    }
}

export default UAProj;