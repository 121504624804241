import React from 'react';

import nutrana1 from './../../assets/nutrana/grafema_nutrana_01.mp4'
import nutrana2 from './../../assets/nutrana/grafema_nutrana_02.mp4'
import nutrana3 from './../../assets/nutrana/grafema_nutrana_03.gif'
import nutrana4 from './../../assets/nutrana/grafema_nutrana_04.jpg'
import nutrana5 from './../../assets/nutrana/grafema_nutrana_05.jpg'
import nutrana6 from './../../assets/nutrana/grafema_nutrana_06.jpg'
import nutrana7 from './../../assets/nutrana/grafema_nutrana_07.mp4'
import nutrana8 from './../../assets/nutrana/grafema_nutrana_08.jpg'
import nutrana9 from './../../assets/nutrana/grafema_nutrana_09.mp4'
import nutrana10 from './../../assets/nutrana/grafema_nutrana_10.jpg'


import {SingleImagePanel, ThreeImagePanel, ProjectTextPanel} from './../componentUtils/projectImgPanel.js'
import * as Utils from './../../utils/utils.js';

class NutranaProj extends React.Component {

    render() {
    
        return (
            <div>
                <SingleImagePanel imgSource={nutrana1}/>
                <ThreeImagePanel 
                    imgSourceLeft={nutrana2}
                    imgSourceMiddle={nutrana3}/>
                <SingleImagePanel imgSource={nutrana4}/>
                <ThreeImagePanel 
                    imgSourceLeft={nutrana5}
                    imgSourceRight={nutrana6}/>
                <SingleImagePanel imgSource={nutrana7}/>
                <SingleImagePanel imgSource={nutrana8}/>
                <ThreeImagePanel 
                    imgSourceLeft={nutrana9}
                    imgSourceMiddle={nutrana10}
                    additionalStyle="lastPanelMarginBottom"/>
                <ProjectTextPanel
                    projectTitle="titleNutrana"
                    projectText="bodyNutrana"
                    projectItems="pageItemsNutrana"
                    projectFotoText="none"
                    navLeftLabel="titleDesignFactory"
                    navRightLabel="titleMaisMenos"
                    navigateTo={this.props.navigateTo}
                    leftNavUrl={Utils.grafemaUrls.designFactoryProject}
                    rightNavUrl={Utils.grafemaUrls.maisMenosProject}
                />
            </div>
        );
    }
}

export default NutranaProj;