import React from 'react';

import set1 from './../../assets/set/grafema_SET_01.mp4'
import set2 from './../../assets/set/grafema_SET_02.mp4'
import set3 from './../../assets/set/grafema_SET_03.mp4'
import set4 from './../../assets/set/grafema_SET_04.jpg'
import set5 from './../../assets/set/grafema_SET_05.jpg'
import set6 from './../../assets/set/grafema_SET_06.jpg'
import set7 from './../../assets/set/grafema_SET_07.jpg'
import set8 from './../../assets/set/grafema_SET_08.jpg'
import set9 from './../../assets/set/grafema_SET_9.jpg'
import set10 from './../../assets/set/grafema_SET_10.jpg'
import set11 from './../../assets/set/grafema_SET_11.jpg'
import set12 from './../../assets/set/grafema_SET_12.jpeg'
import set13 from './../../assets/set/grafema_SET_13.jpg'
import set14 from './../../assets/set/grafema_SET_14.jpg'
import set15 from './../../assets/set/grafema_SET_15.jpg'
import set16 from './../../assets/set/grafema_SET_16.jpg'
import set17 from './../../assets/set/grafema_SET_17.jpg'
import set18 from './../../assets/set/grafema_SET_18.mp4'
import set19 from './../../assets/set/grafema_SET_19.jpg'
import set20 from './../../assets/set/grafema_SET_20.jpg'
import set21 from './../../assets/set/grafema_SET_21.jpg'
import set22 from './../../assets/set/grafema_SET_22.jpg'
import set23 from './../../assets/set/grafema_SET_23.jpg'

import {SingleImagePanel, ThreeImagePanel, ProjectTextPanel} from './../componentUtils/projectImgPanel.js'
import * as Utils from './../../utils/utils.js';

class SetProj extends React.Component {

    render() {
    
        return (
            <div>
                <SingleImagePanel imgSource={set1}/>
                <ThreeImagePanel 
                    imgSourceLeft={set2}
                    imgSourceMiddle={set3}/>
                <SingleImagePanel imgSource={set4}/>
                <ThreeImagePanel 
                    imgSourceLeft={set5}
                    imgSourceRight={set6}/>
                <SingleImagePanel imgSource={set7}/>
                <SingleImagePanel imgSource={set8}/>
                <SingleImagePanel imgSource={set9}/>
                <SingleImagePanel imgSource={set10}/>
                <ThreeImagePanel 
                    imgSourceMiddle={set11}
                    imgSourceRight={set12}/>
                <SingleImagePanel imgSource={set13}/>
                <SingleImagePanel imgSource={set14}/>
                <ThreeImagePanel 
                    imgSourceMiddle={set15}
                    imgSourceRight={set16}/>
                <SingleImagePanel imgSource={set17}/>
                <SingleImagePanel imgSource={set18}/>
                <SingleImagePanel imgSource={set19}/>
                <ThreeImagePanel 
                    imgSourceLeft={set20}
                    imgSourceMiddle={set21}/>
                <SingleImagePanel imgSource={set22}/>
                <SingleImagePanel imgSource={set23}
                    additionalStyle="lastPanelMarginBottom"/>

                <ProjectTextPanel
                    projectTitle="titleSet"
                    projectText="bodySet"
                    projectItems="pageItemsSet"
                    projectFotoText="none"
                    navLeftLabel="titleCaleOiro"
                    navRightLabel="titleCapitolio"
                    navigateTo={this.props.navigateTo}
                    leftNavUrl={Utils.grafemaUrls.caleOiroPackageProject}
                    rightNavUrl={Utils.grafemaUrls.capitolioProject}
                />
            </div>
        );
    }
}

export default SetProj;