import React from 'react';

import capitolio1 from './../../assets/capitolio/grafema_capitolio_01.mp4'
import capitolio2 from './../../assets/capitolio/grafema_capitolio_02.jpg'
import capitolio3 from './../../assets/capitolio/grafema_capitolio_03.jpg'
import capitolio4 from './../../assets/capitolio/grafema_capitolio_04.jpg'
import capitolio5 from './../../assets/capitolio/grafema_capitolio_05.jpg'
import capitolio6 from './../../assets/capitolio/grafema_capitolio_06.jpg'
import capitolio7 from './../../assets/capitolio/grafema_capitolio_07.jpg'
import capitolio8 from './../../assets/capitolio/grafema_capitolio_08.jpg'
import capitolio9 from './../../assets/capitolio/grafema_capitolio_09.jpg'
import capitolio10 from './../../assets/capitolio/grafema_capitolio_10.jpg'
import capitolio11 from './../../assets/capitolio/grafema_capitolio_11.mp4'
import capitolio12 from './../../assets/capitolio/grafema_capitolio_12.jpg'
import capitolio13 from './../../assets/capitolio/grafema_capitolio_13.jpg'
import capitolio14 from './../../assets/capitolio/grafema_capitolio_14.jpg'
import capitolio15 from './../../assets/capitolio/grafema_capitolio_15.jpg'


import {SingleImagePanel, ThreeImagePanel, ProjectTextPanel} from './../componentUtils/projectImgPanel.js'
import * as Utils from './../../utils/utils.js';

class CapitolioProj extends React.Component {

    render() {
    
        return (
            <div>
                <SingleImagePanel imgSource={capitolio1}/>
                <ThreeImagePanel 
                    imgSourceLeft={capitolio2}
                    imgSourceRight={capitolio3}/>
                <SingleImagePanel imgSource={capitolio4}/>
                <SingleImagePanel imgSource={capitolio5}/>
                <SingleImagePanel imgSource={capitolio6}/>
                <SingleImagePanel imgSource={capitolio7}/>
                <ThreeImagePanel 
                    imgSourceLeft={capitolio8}
                    imgSourceRight={capitolio9}/>
                <SingleImagePanel imgSource={capitolio10}/>
                <SingleImagePanel imgSource={capitolio11}/>
                <SingleImagePanel imgSource={capitolio12}/>
                <ThreeImagePanel 
                    imgSourceMiddle={capitolio13}
                    imgSourceRight={capitolio14}/>
                <SingleImagePanel imgSource={capitolio15}
                    additionalStyle="lastPanelMarginBottom"/>

                <ProjectTextPanel
                    projectTitle="titleCapitolio"
                    projectText="bodyCapitolio"
                    projectItems="pageItemsCapitolio"
                    projectFotoText="none"
                    navLeftLabel="titleSet"
                    navRightLabel="titleUmmi"
                    navigateTo={this.props.navigateTo}
                    leftNavUrl={Utils.grafemaUrls.setProject}
                    rightNavUrl={Utils.grafemaUrls.ummitProject}
                />
            </div>
        );
    }
}

export default CapitolioProj;